export const PIPELINE_SCOPE = 'INTEGRATION_SCOPE_PUBLIC';

export const CONTROL_API_VERSION = `/v1.1`;

export const PATH = {
  EventTraces: `${CONTROL_API_VERSION}/event_traces`,
  GeneratedEventSchemas: `${CONTROL_API_VERSION}/generated_event_schemas`,
  GeneratedEventSchemasDetails: `${CONTROL_API_VERSION}/generated_event_schemas/details`,
  GeneratedEventSchemasSummary: `${CONTROL_API_VERSION}/generated_event_schemas/summary`,
  Pipeline: `${CONTROL_API_VERSION}/pipeline`,
  PipelineDiff: `${CONTROL_API_VERSION}/pipeline/diff`,
  TransformEventWithPlaybook: `${CONTROL_API_VERSION}/test/transform_event_with_playbook`,
  ValidateIntegration: `${CONTROL_API_VERSION}/test/validate_integration`,
};

export const METHOD = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  UPDATE: 'UPDATE',
};
