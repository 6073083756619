import React from 'react';
import PropTypes from 'prop-types';
import { useOpen } from '@ion/components';
import { useTrackWithUser } from '@ion/api';
import ViewButton from './ViewButton';

const TRACKING_EVENT_NAME = 'event_debugger_drawer_viewed';

export default function DrawerCell({ buttonLabel = 'View', Drawer, DrawerProps, disabled = false, disabledLabel }) {
  const [trackDrawerViewed] = useTrackWithUser(TRACKING_EVENT_NAME);
  const [isOpen, onOpen, onClose] = useOpen();

  const onClickButton = () => {
    onOpen();
    trackDrawerViewed();
  };

  return (
    <>
      <ViewButton onClick={onClickButton} disabled={disabled} disabledLabel={disabledLabel}>
        {buttonLabel}
      </ViewButton>
      <Drawer onClose={onClose} open={isOpen} {...DrawerProps} />
    </>
  );
}

DrawerCell.propTypes = {
  buttonLabel: PropTypes.string,
  Drawer: PropTypes.elementType.isRequired,
  DrawerProps: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  disabledLabel: PropTypes.string,
};
