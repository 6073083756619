import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ schemaId, name, schemaDescription, jsonSchema, version }) {
  const action = actions.schema_updated;
  const description = `${name}`;
  const debug_data = {
    schemaId,
    name,
    description: schemaDescription,
    jsonSchema,
    version,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
