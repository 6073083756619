import { useState, useCallback } from 'react';

import { useOpen } from '@ion/components';

import buildSchemasConfirmDeleteProps from './buildSchemasConfirmDeleteProps';
import useConfirmRemoveSchema from './useConfirmRemoveSchema';

export default function useSchemasConfirmDeleteProvider() {
  const [isOpen, onOpen, onClose] = useOpen();
  const [selectedSchema, setSelectedSchema] = useState({});

  const openDeleteModal = useCallback(
    row => {
      setSelectedSchema(row);
      onOpen();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onConfirm = useConfirmRemoveSchema({ selectedSchema, onClose });
  const name = selectedSchema?.name;

  const confirmDeleteProps = buildSchemasConfirmDeleteProps({ open: isOpen, name, onClose, onConfirm });

  return {
    confirmDeleteProps,
    onOpen: openDeleteModal,
  };
}
