import { gql } from '@apollo/client';
import schema from '@ion/api/schemas/schema';
import schemaVersion from '@ion/api/schemas/schemaVersion';

export const mutation = gql`
  mutation UpdateSchema($schema_versions_insert_input: schema_versions_insert_input!) {
    insert_schema_versions_one(object: $schema_versions_insert_input) {
      ...schemaVersion
      schema {
        ...schema
      }
    }
  }
  ${schemaVersion}
  ${schema}
`;

export function buildVariables({ name, description, jsonSchema, schemaId, version }) {
  return {
    schema_versions_insert_input: {
      jsonSchema,
      version,
      schema: {
        on_conflict: { constraint: 'schemas_pkey', update_columns: ['description', 'name'] },
        data: { description, name, id: schemaId },
      },
    },
  };
}
