export default {
  REQUEST_NAME: 'namespaceData',
  REQUEST_INPUT_NAME: 'keys',
  SINGLE_SELECT_DESCRIPTION: 'Namespace Data',
  DESCRIPTION: 'This sync allows you to bring your own data from a namespace variable.',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'name',
      title: 'Key Name',
      description: 'The name that will be used when sending the data to the ingestor.',
      required: true,
      default: '',
      placeholder: 'e.g., color',
    },
    {
      type: 'TEXT',
      property: 'path',
      title: 'Namespace Path',
      description: 'The name of the variable in the namespace where the data is stored.',
      required: true,
      default: '',
      placeholder: 'e.g., preferences.color',
    },
  ],
};
