import React from 'react';

import SchemasConfirmDeleteMessage from 'app/schema-enforcement/SchemasTab/components/SchemasConfirmDeleteMessage';

export default function buildSchemasConfirmDeleteProps({ open, name, onClose, onConfirm }) {
  return {
    open,
    title: `Delete ${name}`,
    message: <SchemasConfirmDeleteMessage name={name} />,
    onClose,
    onConfirm,
  };
}
