import React from 'react';
import propTypes from 'prop-types';

import { MrAppMain } from '@ion/components';

// ND - Tech Debt - Settings has a weird hack to make the page look normal wihin a Tab.
// all of this junk needs to be refactored. At least it's pulling from the same place.
import settingsStyles from 'app/settings/index.module.scss';

export default function MrTabPanelBase({ header, children }) {
  return (
    <MrAppMain className={settingsStyles.main} header={header}>
      {children}
    </MrAppMain>
  );
}

MrTabPanelBase.propTypes = {
  children: propTypes.element,
  header: propTypes.element,
};
