import React, { useEffect, useMemo } from 'react';
import pt from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { MrLoading } from '@ion/components';
import OrgOverrideBanner from '@ion/app/src/components/org-override/org-override-banner';
import { useLogin } from '@ion/user';
import * as Sentry from '@sentry/react';
import useLaunchDarklyContextFlags from '../../launch-darkly/useLaunchDarklyContextFlags';
import useIdentifyHotJarUser from 'app/hot-jar/useIdentifyHotJarUser';
import usePage from 'app/analytics/use-page.js';
import useIdentify from 'app/analytics/use-identify';
import userLazyQueryUserOrganizations from '@ion/api/auth0/useLazyQueryUserOrganizations';
import { routes } from 'app/constants';

const ProtectedRoute = ({ component: Component, redirect, ...props }) => {
  const [{ allFlagsReady }, setLaunchDarklyContext] = useLaunchDarklyContextFlags();
  const invitationParams = useInvitationParms();
  const { user, currentOrg, isLoading, error } = useLogin(invitationParams);
  const [getUserOrganizations] = userLazyQueryUserOrganizations();

  useIdentifyHotJarUser();
  useIdentify();
  usePage(location.pathname);
  useEffect(() => {
    if (!isLoading && !error) {
      getUserOrganizations();
    }
  }, [getUserOrganizations, isLoading, error]);

  if (error) {
    return handleError(error);
  }

  if (isLoading) {
    return <MrLoading />;
  }

  if (!allFlagsReady) {
    if (user.email) {
      Sentry.setUser({
        email: user.email,
      });
      setLaunchDarklyContext({ user, currentOrg });
      return <MrLoading />;
    } else {
      return handleError({
        error: 'no_email',
        error_description: 'User does not have an assigned email address',
      });
    }
  }

  // IMPORTANT! Make sure the login logic runs before the redirect
  // to prevent invitation issues.
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Route
      {...props}
      render={props => {
        return (
          <>
            <OrgOverrideBanner />
            <Component {...props} banner />
          </>
        );
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: pt.func,
  redirect: pt.string,
  path: pt.string,
};

export default ProtectedRoute;

function useInvitationParms() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const organization = params.get('organization');
  const invitation = params.get('invitation');

  return useMemo(() => ({ organization, invitation }), [organization, invitation]);
}

const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';

function handleError({ error, error_description }) {
  if (error === 'access_denied') {
    return <Redirect to={routes.noOrgsFound} />;
  }
  if (error === 'no_email') {
    return <Redirect to={routes.noUserEmail} />;
  }
  const message = error_description || error || DEFAULT_ERROR_MESSAGE;
  throw new Error(message);
}
