import React from 'react';
import pt from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';
import { Grid } from '@metarouter-private/mr-mui';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';
import SchemaCodeEditor from './SchemaCodeEditor';
import SchemaTextFields from './SchemaTextFields';
import SchemaVersionSelect from './SchemaVersionSelect';

export default function SchemaForm() {
  const { schemaVersions } = useSchemaContext();
  return (
    <Grid container spacing={2}>
      {!_isEmpty(schemaVersions) && (
        <Grid item xs={12}>
          <SchemaVersionSelect schemaVersions={schemaVersions} />
        </Grid>
      )}
      <Grid item xs={12}>
        <SchemaTextFields />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SchemaCodeEditor />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SchemaForm.propTypes = {
  name: pt.string,
  description: pt.string,
  jsonSchema: pt.string,
};
