import React, { useState } from 'react';

import pt from 'prop-types';

import s from './index.module.scss';
import Row from './row';
import cx from 'classnames';
import Depth from './depth';

const renderRows = (properties, depth, openProps, setOpenProps, parent = '') => {
  return Object.entries(properties).reduce((result, [property, values]) => {
    const key = parent + property + depth;

    if (openProps[key] === null && !!values && (values?.properties || values?.items)) {
      openProps[key] = false;
    }

    const toggleOpen = () => {
      const newProps = { ...openProps };
      newProps[key] = !openProps[key];
      setOpenProps(newProps);
    };

    result.push(
      <Row
        key={key}
        depth={depth}
        property={property}
        isOpen={openProps[key]}
        setIsOpen={toggleOpen}
        properties={values.properties || values.items}
        {...values}
      />
    );

    if (values.properties && openProps[key]) {
      result.push(...renderRows(values.properties, depth + 1, openProps, setOpenProps, property));
    }

    if (values.items && openProps[key]) {
      result.push(
        <div key={key + 'array'} className={cx(s.array, Depth[depth + 1])}>
          <div className={s.text}>Schema of the first array item</div>
          {renderRows(values.items, 1, openProps, setOpenProps, property)}
        </div>
      );
    }

    return result;
  }, []);
};

const SchemaTree = ({ properties }) => {
  const [openProps, setOpenProps] = useState({});

  return (
    <div className={s.container}>
      <div className={s.title}>Schema Tree</div>
      <div className={s.list}>{renderRows(properties, 0, openProps, setOpenProps)}</div>
    </div>
  );
};

SchemaTree.propTypes = {
  properties: pt.object, // Todo better define
};

export default SchemaTree;
