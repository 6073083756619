import React from 'react';
import propTypes from 'prop-types';
import TextCell from 'app/components/data-table/TextCell';

export default function DescriptionCell({ description }) {
  return <TextCell text={description} />;
}

DescriptionCell.propTypes = {
  description: propTypes.string,
};
