import React from 'react';
import propTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function AssignedTo({ pipelines = [] }) {
  const joinedPipelines = pipelines.map(pipeline => pipeline.name).join(', ');

  return <TextCell text={joinedPipelines} />;
}

AssignedTo.propTypes = {
  pipelines: propTypes.array,
};
