export const PLAYBOOK = {
  GLOBAL: 'global',
  EVENT_SPECIFIC: 'eventSpecific',
  DEFAULT: 'default',
};
export const PLAYBOOK_STANZA = {
  MAPPINGS: 'mapping',
  FILTERS: 'filters',
  ENRICHMENTS: 'enrichments',
  SPREADINGS: 'spreadings',
  EXPRESSIONS: 'expressions',
};

export const PLAYBOOK_PARAMETER = {
  PARAMETER: 'params',
};
