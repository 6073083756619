import React from 'react';
import proptypes from 'prop-types';
import { Grid, IconButton, XCloseIcon, Typography } from '@metarouter-private/mr-mui';
import ClusterSelect from 'app/components/cluster-select';

import { useTransformPlaybookContext } from '../../../context/transform-playbook-context';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

const DrawerHeader = ({ onCloseDrawer }) => {
  const { setClusterId, clusterId } = useTransformPlaybookContext();
  const [trackClusterSelected] = useTrackWithUser('cluster_selected');

  const handleOnChange = event => {
    trackClusterSelected();
    setClusterId(event);
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={5}>
        <Typography variant="h6">Test Playbook</Typography>
      </Grid>
      <Grid item xs={6}>
        <ClusterSelect onChange={handleOnChange} defaultValue={clusterId} />
      </Grid>
      <Grid item>
        <IconButton onClick={onCloseDrawer}>
          <XCloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
export default DrawerHeader;

DrawerHeader.propTypes = {
  onCloseDrawer: proptypes.func.isRequired,
};
