import React from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import stringify from 'json-stringify-pretty-compact';

import { Drawer, Stack, Typography } from '@metarouter-private/mr-mui';

import { MrCodeEditor } from '@ion/components';

const REQUEST_HEADERS_SECTION_TITLE = 'Request Headers';
const REQUEST_BODY_SECTION_TITLE = 'Request Body';

export default function RequestDrawer({ requestHeaders, requestBody, open, onClose }) {
  const decodedHeaders = base64.decode(requestHeaders);

  const decodedBody = base64.decode(requestBody);
  const formattedBody = decodedBody ? stringify(JSON.parse(decodedBody)) : '';

  return (
    <Drawer anchor="right" onClose={onClose} open={open} width="60%">
      <Stack padding={3} spacing={2}>
        <Typography variant="h3">{REQUEST_HEADERS_SECTION_TITLE}</Typography>

        <MrCodeEditor rows={30} name="request-drawer-headers" disabled lang="text" value={decodedHeaders} />
      </Stack>

      <Stack padding={3} spacing={2}>
        <Typography variant="h3">{REQUEST_BODY_SECTION_TITLE}</Typography>

        <MrCodeEditor rows={30} name="request-drawer-body" disabled lang="json" value={formattedBody} />
      </Stack>
    </Drawer>
  );
}

RequestDrawer.propTypes = {
  requestHeaders: PropTypes.string.isRequired,
  requestBody: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
