import React, { useState, useEffect } from 'react';

import pt from 'prop-types';

import { MrH, MrLink, MrIcon } from '@ion/components';

import Parameter from 'app/playbook-editor/parameters/editor-panel/parameter';

import NewEventForm from 'app/playbook-editor/parameters/editor-panel/new-event-form';
import DeleteEvent from './modals/delete-event';

import s from './index.module.scss';
import Filters from './filters/Filters';
import { PLAYBOOK_PARAMETER } from '../../constants';
import { PLAYBOOK_FILTERS_FLAG } from '../playbook-filters-flag';
import FeatureGate from '../../../launch-darkly/gates/feature-gate';

const EditorPanel = ({ navState, playbookState, isAllowed }) => {
  //Get the array operations from the nav State
  let operations = playbookState.getOperationsFromSection(navState.selectedSection);
  if (navState.selectedSection === 'eventSpecific') {
    operations = operations[navState.selectedEvent] ?? [];
  }
  const renderNewOperationCard =
    navState.selectedSection === 'eventSpecific'
      ? playbookState.newParameters.eventSpecific[navState.selectedEvent]
      : playbookState.newParameters[navState.selectedSection];

  const [deleteEventName, setDeleteEventName] = useState(null);
  const isNewEvent = navState.selectedEvent === 'newEvent';

  const isExpanded = operation => {
    return operation.opId === navState.selectedTransformation;
  };

  // When the user clicks Add Parameter, this will let you scroll down.
  useEffect(() => {
    const element = document.getElementById('newParam');
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [navState]);

  const sectionName = {
    params: 'Parameters',
    global: 'Global',
    eventSpecific: 'Event Specific',
    default: 'Default',
  };

  return (
    <div className={s.cardList}>
      <div className={s.top}>
        <MrH h="h2" sans styleNames="noMargin">
          {`${navState.sectionNumber}. ${sectionName[navState.selectedSection]}`}
          {navState.selectedEvent && `: ${navState.selectedEvent}`}
        </MrH>
        <div className={s.new}>
          {!isNewEvent && isAllowed && navState.selectedSection !== 'params' && (
            <>
              <MrIcon id="plus-circle" fontSize="medium" />
              <MrLink
                className={s.addParamLink}
                onClick={() => {
                  playbookState.createNewParameterForSection(navState.selectedSection, navState.selectedEvent);
                }}
                data-test-id={'newParamLink'}
              >
                Add Parameter
              </MrLink>
            </>
          )}
          {navState.selectedSection === 'eventSpecific' && !isNewEvent && isAllowed && (
            <>
              <MrIcon id="trash-02" fontSize="medium" />
              <MrLink
                className={s.addParamLink}
                onClick={() => {
                  setDeleteEventName(navState.selectedEvent);
                  // setHasUnsavedChanges(true)
                }}
                data-test-id={'newParamLink'}
              >
                Delete Event
              </MrLink>
            </>
          )}
        </div>
      </div>
      {!isNewEvent && navState.selectedSection !== PLAYBOOK_PARAMETER.PARAMETER && (
        <FeatureGate flagName={PLAYBOOK_FILTERS_FLAG}>
          <Filters navState={navState} playbookState={playbookState} />
        </FeatureGate>
      )}

      {!isNewEvent &&
        operations.map(operation => {
          return (
            <Parameter
              isAllowed={isAllowed}
              playbookState={playbookState}
              navState={navState}
              key={operation.opId}
              operationData={operation}
              isExpanded={isExpanded(operation)}
            />
          );
        })}

      {renderNewOperationCard && (
        <Parameter
          isAllowed={isAllowed}
          playbookState={playbookState}
          navState={navState}
          isExpanded
          operationData={renderNewOperationCard}
        />
      )}

      {isNewEvent && <NewEventForm navState={navState} playbookState={playbookState} isAllowed={isAllowed} />}
      {deleteEventName && (
        <DeleteEvent
          playbookState={playbookState}
          deleteEventName={deleteEventName}
          setDeleteEventName={setDeleteEventName}
          navState={navState}
        />
      )}
    </div>
  );
};

EditorPanel.propTypes = {
  navState: pt.shape({
    selectedSection: pt.string.isRequired,
    selectedEvent: pt.string,
    selectedTransformation: pt.string,
    sectionNumber: pt.number.isRequired,
  }).isRequired,
  playbookState: pt.shape({
    getOperationsFromSection: pt.func,
    newParameters: pt.object.isRequired,
    createNewParameterForSection: pt.func,
  }).isRequired,
  isAllowed: pt.bool.isRequired,
};

export default EditorPanel;
