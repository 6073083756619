import React from 'react';

import EventNameCell from './cells/EventNameCell';
import DescriptionCell from './cells/DescriptionCell';
import SchemaLibraryAssignmentsCell from './cells/SchemaLibraryAssignmentsCell';
import ActionsCell from './cells/ActionsCell';

export const EventsTableColumns = [
  {
    name: 'Event',
    cell: row => <EventNameCell name={row.name} />,
  },
  {
    name: 'Description',
    cell: row => <DescriptionCell description={row.description} />,
  },
  {
    name: 'Schema Library Assignments',
    cell: row => <SchemaLibraryAssignmentsCell assignmentsCount={row.assignmentsCount} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
