import React from 'react';
import { PageReactDataTable } from '@metarouter-private/mr-mui';
import { COLUMNS } from './columns';

import SchemasTableActions from './SchemasTableActions';
import useSchemasTable from './useSchemasTable';
import SchemasVersionConfirmDeleteContextProvider from 'app/schema-enforcement/SchemasTab/context/SchemasConfirmDeleteContextProvider';

const PER_PAGE = 10;

export default function SchemasTable() {
  const schemasTable = useSchemasTable();
  return (
    <SchemasVersionConfirmDeleteContextProvider>
      <PageReactDataTable columns={COLUMNS} perPage={PER_PAGE} {...schemasTable} actions={<SchemasTableActions />} />
    </SchemasVersionConfirmDeleteContextProvider>
  );
}
