import React from 'react';
import base64 from 'base-64';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import DrawerCell from './DrawerCell';
import EventDrawer from './EventDrawer';

export default function EventCell({ title, encodedValue, buttonLabel = 'View', lang = 'json' }) {
  const event = base64.decode(encodedValue);
  const disabled = isEmpty(event);
  const disabledLabel = `No ${title}`;

  const DrawerProps = {
    title,
    event,
    lang,
  };

  return (
    <DrawerCell
      buttonLabel={buttonLabel}
      Drawer={EventDrawer}
      DrawerProps={DrawerProps}
      disabled={disabled}
      disabledLabel={disabledLabel}
    />
  );
}

EventCell.propTypes = {
  title: PropTypes.string.isRequired,
  encodedValue: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  lang: PropTypes.string,
};
