import React from 'react';
import pt from 'prop-types';

import { MrInputSelectDeprecated, formValidators } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

import s from './operation.module.scss';

const { validateRequired } = formValidators;

const options = {
  mappings: [
    {
      text: 'Direct Assign',
      value: 'directAssignment',
    },
    {
      text: 'To String',
      value: 'toScalarString',
    },
    {
      text: 'To Float',
      value: 'toScalarFloat',
    },
    {
      text: 'To Truncated Integer',
      value: 'toScalarTruncatedInt',
    },
    {
      text: 'To Rounded Integer',
      value: 'toScalarRoundedInt',
    },
    {
      text: 'To Boolean',
      value: 'toScalarBool',
    },
    {
      text: 'To Hash',
      value: 'toHash',
    },
    {
      text: 'Truncate Float',
      value: 'truncateFloat',
    },
    {
      text: 'Trim String',
      value: 'modifyStringTrim',
    },
    {
      text: 'Uppercase String',
      value: 'modifyStringUppercase',
    },
    {
      text: 'Lowercase String',
      value: 'modifyStringLowercase',
    },
    {
      text: 'Substring',
      value: 'substring',
    },
    {
      text: 'Split String',
      value: 'splitString',
    },
    {
      text: 'Replace String',
      value: 'replaceString',
    },
    {
      text: 'Pluck Values',
      value: 'pluckValues',
    },
    {
      text: 'Join Values',
      value: 'joinValues',
    },
    {
      text: 'To Date Time',
      value: 'toDateTime',
    },
  ],
  enrichments: [
    {
      text: 'Direct Value',
      value: 'directValue',
    },
    {
      text: 'UUID',
      value: 'uuid',
    },
    {
      text: 'Timestamp',
      value: 'timestamp',
    },
    {
      text: 'Date',
      value: 'date',
    },
  ],
  expressions: [
    { text: 'Multi-Key', value: 'multiKey' },
    { text: 'Single-Key', value: 'singleKey' },
  ],
  spreadings: [{ text: 'Flatten', value: 'flatten' }],
};
const SelectSubOperation = ({ name, selectedOperation, selectedSubOperation }) => {
  const { setField, validateField, formState } = useFormContext();
  return (
    <MrInputSelectDeprecated
      name={name}
      options={options[selectedOperation] || []}
      value={selectedSubOperation}
      onChange={event => {
        setField(event);
      }}
      onBlur={validateField}
      errorMsg={formState[name]?.errorMsg}
      placeholder
      label="Type:"
      disabled={!selectedOperation}
      validators={[validateRequired()]}
      labelPosition="left"
      className={s.selectOperation}
      inputClassName={s.selectInput}
      dropdownClassName={s.selectDropdown}
      dropdownPosition="right"
    />
  );
};

SelectSubOperation.propTypes = {
  name: pt.string.isRequired,
  selectedOperation: pt.string,
  selectedSubOperation: pt.string,
};

export default SelectSubOperation;
