import { useCallback } from 'react';
import { useRemoveSchema } from '@ion/api';

export default function useConfirmRemoveSchema({ selectedSchema, onClose }) {
  const [removeSchema] = useRemoveSchema({
    onCompleted: onClose,
  });

  return useCallback(
    () => {
      removeSchema({
        id: selectedSchema.id,
        name: selectedSchema.name,
        description: selectedSchema.description,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSchema.id]
  );
}
