import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';

const EventDebuggerContext = createContext(null);

export default function EventDebuggerPageContextProvider({ children }) {
  const [selectedCluster, setSelectedCluster] = React.useState('');
  const [refreshedAt, setRefreshedAt] = React.useState(Date.now());
  return (
    <EventDebuggerContext.Provider value={{ selectedCluster, setSelectedCluster, refreshedAt, setRefreshedAt }}>
      {children}
    </EventDebuggerContext.Provider>
  );
}

export function useEventDebuggerContext() {
  return useContext(EventDebuggerContext);
}

EventDebuggerPageContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
