import React from 'react';
import PropTypes from 'prop-types';

import { MrForm } from '@ion/components';
import { Box } from '@metarouter-private/mr-mui';

import PlaybookFiltersContextProvider from './context/playbook-filters-context';
import { FormProvider } from '../../../../context/form-context';
import FiltersAccordionSection from './FiltersAccordionSection';
import useFilters from './use-filters';

export default function Filters({ navState, playbookState }) {
  const { formId, submitForm, filtersPath, filtersValue, onSubmit } = useFilters({ navState, playbookState });

  return (
    <MrForm id={formId}>
      <FormProvider value={{ submitForm }}>
        <Box pb={2}>
          <PlaybookFiltersContextProvider filtersPath={filtersPath} filtersValue={filtersValue}>
            <FiltersAccordionSection navState={navState} onSubmit={onSubmit} />
          </PlaybookFiltersContextProvider>
        </Box>
      </FormProvider>
    </MrForm>
  );
}

Filters.propTypes = {
  navState: PropTypes.object,
  playbookState: PropTypes.object,
};
