import React from 'react';

import SchemasTable from './components/SchemasTable';
import SchemaContextProvider from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';

export default function SchemasTab() {
  return (
    <SchemaContextProvider>
      <SchemasTable />
    </SchemaContextProvider>
  );
}
