import copy from 'copy-to-clipboard';
import isAllowedToDeleteInvitation from '../../../permissions/is-allowed-to-delete-invitation';
import { useInvitationConfirmDeleteContext } from '../../../context/InvitationConfirmDeleteContextProvider';

export const useBuildMenuItems = row => {
  const { onOpen } = useInvitationConfirmDeleteContext();
  const hasPermission = isAllowedToDeleteInvitation();

  return [
    {
      label: 'Delete',
      onClick: () => onOpen(row),
      props: { disabled: !hasPermission },
    },
    {
      label: 'Copy URL',
      onClick: () => copy(row.invitationUrl),
    },
  ];
};
