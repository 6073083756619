import React from 'react';
import { useTrackPage } from '@ion/api';

import { MrTwoPaneLayout } from '@ion/components';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';

import { ConsentCategoriesTable, Documentation, ConsentCategoryModal } from './components';

export const ConsentCategoryPage = () => {
  useTrackPage('consent_settings_page_view');

  return (
    <>
      <RedirectFeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
        <ConsentCategoryModal />
        <MrTwoPaneLayout paneOne={<Documentation />} paneTwo={<ConsentCategoriesTable />} />
      </RedirectFeatureGate>
    </>
  );
};
