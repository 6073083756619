import React from 'react';
import { Button, Grid, RefreshIcon } from '@metarouter-private/mr-mui';
import ClusterSelect from '@ion/app/src/components/cluster-select';
import { useEventDebuggerContext } from '../context/EventDebuggerPageContextProvider';
import { useTrackWithUser } from '@ion/api';

const EventDebuggerPageHeader = () => {
  const [trackClusterSelected] = useTrackWithUser('event_debugger_cluster_selected');
  const { setSelectedCluster, setRefreshedAt } = useEventDebuggerContext();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={4} lg={3}>
        <ClusterSelect onChange={setSelectedCluster} onOpen={() => trackClusterSelected()} />
      </Grid>
      <Grid item xs={2}>
        <Button size="large" onClick={() => setRefreshedAt(Date.now())} startIcon={<RefreshIcon />}>
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
};

export default EventDebuggerPageHeader;
