import React from 'react';
import pt from 'prop-types';
import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import isAllowedToCreateSchemas from '@ion/api/schemas/permissions/isAllowedToCreateSchemas';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';
import { SchemaModalOptions } from 'app/schema-enforcement/SchemasTab/context/SchemaModalOptions';

const title = 'New Schema';

export default function NewSchemaButton({ onClick }) {
  const { onClear, setSchemaFormModalOption } = useSchemaContext();
  const hasPermission = isAllowedToCreateSchemas();
  const handleOnClick = () => {
    onClear();
    setSchemaFormModalOption(SchemaModalOptions.create);
    onClick();
  };
  return (
    <Button startIcon={<PlusCircleIcon />} size="large" disabled={!hasPermission} onClick={handleOnClick}>
      {title}
    </Button>
  );
}

NewSchemaButton.propTypes = {
  onClick: pt.func.isRequired,
};
