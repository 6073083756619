import React from 'react';

import { Stack, TwoPaneLayout } from '@metarouter-private/mr-mui';

import { MrAppMain } from '@ion/components';
import { UserManagementTable } from './components/UserManagementTable';
import { UserInvitationsTable } from './components/invitations-table/UserInvitationsTable';
import { default as Documentation } from './components/documentation';

export const UserManagementPage = () => {
  return (
    <MrAppMain styleNames="noBackground">
      <TwoPaneLayout
        paneOne={<Documentation />}
        paneTwo={
          <Stack spacing={2}>
            <UserManagementTable />
            <UserInvitationsTable />
          </Stack>
        }
      />
    </MrAppMain>
  );
};
