import React from 'react';
import PropTypes from 'prop-types';

import { Edit03Icon, IconButton } from '@metarouter-private/mr-mui';

import isAllowedToUpdateSchemas from '@ion/api/schemas/permissions/isAllowedToUpdateSchemas';

export default function EditEventButton({ onClick }) {
  const hasPermission = isAllowedToUpdateSchemas();

  return (
    <IconButton data-test-id={'editEventButton'} disabled={!hasPermission} onClick={onClick}>
      <Edit03Icon />
    </IconButton>
  );
}

EditEventButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
