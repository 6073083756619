import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';
import { TimeAgo, FormattedDate } from '@metarouter-private/mr-mui';

export default function TimeAgoAndDate({ timestamp, TimeAgoProps, FormattedDateProps }) {
  const timestampDate = new Date(timestamp);

  const timeAgoPropsWithVariant = {
    date: timestampDate,
    TypographyProps: {
      variant: 'body2',
    },
    ...TimeAgoProps,
  };

  const formattedDateWithVariant = {
    date: timestampDate,
    TypographyProps: {
      variant: 'caption',
    },
    ...FormattedDateProps,
  };

  return (
    <Stack direction="column">
      <TimeAgo {...timeAgoPropsWithVariant} />

      <FormattedDate {...formattedDateWithVariant} />
    </Stack>
  );
}

TimeAgoAndDate.propTypes = {
  timestamp: PropTypes.string.isRequired,
  TimeAgoProps: PropTypes.object,
  FormattedDateProps: PropTypes.object,
};
