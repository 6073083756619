import React from 'react';

import { Edit03Icon, UserPlus01Icon } from '@metarouter-private/mr-mui';

import UserFormFields from '../components/modal/UserFormFields';

import addHelperText from '../fixtures/add/helper-text';
import addTitle from '../fixtures/add/title';

import editHelperText from '../fixtures/edit/helper-text';
import editSubmitLabel from '../fixtures/edit/submit-label';
import editTitle from '../fixtures/edit/title';

export const USER_MODAL_OPTIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

const USER_FORM_MODAL_COMMON_PROPS = {
  children: <UserFormFields />,
};

export const USER_FORM_MODAL_PROPS = {
  [USER_MODAL_OPTIONS.ADD]: {
    ...USER_FORM_MODAL_COMMON_PROPS,
    submitLabel: addTitle,
    TitleIcon: UserPlus01Icon,
    title: addTitle,
    helperText: addHelperText,
  },
  [USER_MODAL_OPTIONS.EDIT]: {
    ...USER_FORM_MODAL_COMMON_PROPS,
    submitLabel: editSubmitLabel,
    TitleIcon: Edit03Icon,
    title: editTitle,
    helperText: editHelperText,
  },
};

export const isValidateUserModalOption = modalOption => {
  switch (modalOption) {
    case USER_MODAL_OPTIONS.ADD:
    case USER_MODAL_OPTIONS.EDIT:
      return true;
    default:
      throw new Error(`Unknown modal option ${modalOption}`);
  }
};
