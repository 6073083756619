import { useEffect } from 'react';
import { isEqual as _isEqual } from 'lodash';
import { useAccordionSectionContext } from '@metarouter-private/mr-mui';

import { PLAYBOOK_STANZA } from '../../../constants';

export default function useOnlyOneExpanded({ selectedTransformation }) {
  const { setExpanded } = useAccordionSectionContext();

  useEffect(() => {
    const selected = _isEqual(selectedTransformation, PLAYBOOK_STANZA.FILTERS);
    if (!selected) {
      setExpanded(false);
      return;
    }

    setExpanded(true);
  }, [setExpanded, selectedTransformation]);
}
