import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function SchemaLibraryAssignmentsCell({ assignmentsCount }) {
  return <TextCell text={assignmentsCount} />;
}

SchemaLibraryAssignmentsCell.propTypes = {
  assignmentsCount: PropTypes.string,
};
