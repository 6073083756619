import React, { useCallback } from 'react';
import YAML from 'yaml';

import { upperFirst as _upperFirst } from 'lodash';

import { MrH } from '@ion/components';

import { PLAYBOOK, PLAYBOOK_STANZA } from '../../../constants';
import FiltersEditor from './FiltersEditor';
import useDisabledSubmitButton from './use-disabled-submit-button';
import { CANCEL_BUTTON_PROPS, SUBMIT_BUTTON_PROPS } from '../parameter/default-button-props';

import styles from '../parameter/header/operation.module.scss';
import { usePlaybookFiltersContext } from './context/playbook-filters-context';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

const submitLabel = 'Apply Changes';

export default function useFiltersAccordionProps({ navState, onSubmit }) {
  const [trackPlaybookFilterOpened] = useTrackWithUser('playbook_filter_opened');
  const { setValue, filters } = usePlaybookFiltersContext();
  const { selectedSection, selectedTransformation } = navState;
  const disabledSubmitButton = useDisabledSubmitButton();

  const onChange = useCallback(
    isExpanded => {
      navState.setTransformation(PLAYBOOK_STANZA.FILTERS);
      if (!isExpanded) {
        trackPlaybookFilterOpened();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTransformation]
  );

  const onCancel = useCallback(() => {
    setValue(YAML.stringify(filters));
  }, [setValue, filters]);

  return {
    summary: buildSummaryElement(selectedSection),
    sx: {
      p: 1.5,
      pr: 3,
    },
    children: <FiltersEditor selectedTransformation={selectedTransformation} />,
    CancelButtonProps: {
      ...CANCEL_BUTTON_PROPS,
      onCancel,
    },
    SubmitButtonProps: {
      ...SUBMIT_BUTTON_PROPS,
      disabled: disabledSubmitButton,
      submitLabel,
      onSubmit,
    },
    onChange,
  };
}

function buildSummaryElement(selectedSection) {
  const summaryText = selectedSection === PLAYBOOK.EVENT_SPECIFIC ? 'event' : selectedSection;
  return (
    <div className={styles.title} key="title">
      <MrH h="h3" styleNames="noMargin ellipsis">
        {_upperFirst(summaryText)} Filters
      </MrH>
    </div>
  );
}
