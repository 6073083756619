import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { upperFirst as _upperFirst } from 'lodash';

import { PLAYBOOK_STANZA } from '../../constants';
import FiltersIndicator from './FiltersIndicator';
import FeatureGate from '../../../launch-darkly/gates/feature-gate';
import { PLAYBOOK_FILTERS_FLAG } from '../playbook-filters-flag';

import s from './index.module.scss';

export default function FiltersSideNav({ selectedTransformation, filtersPath, filters, scrollTo }) {
  return (
    <FeatureGate flagName={PLAYBOOK_FILTERS_FLAG}>
      <li
        className={cx(s.ellipsis, PLAYBOOK_STANZA.FILTERS === selectedTransformation ? s.current : null)}
        onClick={() => scrollTo({ opId: PLAYBOOK_STANZA.FILTERS })}
        data-test-id={`${filtersPath}InnerSideNav`}
      >
        <div>
          {_upperFirst(filtersPath)} Filters <FiltersIndicator filters={filters} />
        </div>
      </li>
    </FeatureGate>
  );
}

FiltersSideNav.propTypes = {
  selectedTransformation: PropTypes.string,
  filtersPath: PropTypes.string,
  filters: PropTypes.array,
  scrollTo: PropTypes.func,
};
