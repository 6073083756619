import React from 'react';
import { Box, Button, Link, Typography, BookOpen02Icon } from '@metarouter-private/mr-mui';
import { useQueryRoles } from '@ion/api';
import { default as RoleList } from './RoleList';

export default function Documentation() {
  const { roles, loading } = useQueryRoles();

  return (
    <Box>
      <Typography variant="h6">User Roles</Typography>
      <br />
      <Typography variant="body2">
        Roles are used to manage access. Each user can only be assigned one role at a time. Below are the available
        roles:
      </Typography>
      <br />
      {!loading && <RoleList roles={roles} />}
      <Link href="https://docs.metarouter.io/docs/user-roles-management" target="_blank">
        <Button variant="outlined" color="secondary" startIcon={<BookOpen02Icon />}>
          Documentation
        </Button>
      </Link>
    </Box>
  );
}
