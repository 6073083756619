import React from 'react';
import PropTypes from 'prop-types';

import { AccordionSection } from '@metarouter-private/mr-mui';

import useFiltersAccordionProps from './use-filters-accordion-props';

export default function FiltersAccordionSection({ navState, onSubmit }) {
  const accordionSectionProps = useFiltersAccordionProps({ navState, onSubmit });
  return <AccordionSection {...accordionSectionProps} />;
}

FiltersAccordionSection.propTypes = {
  navState: PropTypes.object,
  onSubmit: PropTypes.func,
};
