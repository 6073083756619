import React from 'react';
import pt from 'prop-types';
import { Avatar, Stack, Typography } from '@metarouter-private/mr-mui';

import StarterKits from 'app/starterkits/starterkits-state';

const IntegrationName = ({ id, TypographyProps }) => {
  const { kits, kitById } = StarterKits();

  // retrieve integration starterkit from starterkits array
  const starterkit = kitById(kits, id);

  let logo;

  const typographyPropsWithVariant = {
    variant: 'body2',
    ...TypographyProps,
  };

  try {
    logo = require(`./logos/${id}.svg`).default;
  } catch (error) {
    return (
      <Stack alignItems="center" direction="row" spacing={1}>
        <Avatar sx={{ width: 32, height: 32 }}>{id.charAt(0).toUpperCase()}</Avatar>
        <Typography component="span" {...typographyPropsWithVariant}>
          {id}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Avatar src={logo} sx={{ width: 32, height: 32 }} />
      <Typography component="span" {...typographyPropsWithVariant}>
        {starterkit?.friendlyName}
      </Typography>
    </Stack>
  );
};

IntegrationName.propTypes = {
  id: pt.string.isRequired,
  TypographyProps: pt.object,
};

export default IntegrationName;
