import React from 'react';
import { Grid, TextField } from '@metarouter-private/mr-mui';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';

export default function SchemaTextFields() {
  const { name, description, setName, setDescription } = useSchemaContext();
  const schemaNameEmpty = name === '';

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          defaultValue={name}
          label="Schema Name"
          onChange={e => setName(e.target.value)}
          error={schemaNameEmpty}
          helperText={schemaNameEmpty ? 'Schema Name is required' : ''}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          defaultValue={description}
          label="Description"
          onChange={e => setDescription(e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
