import { useMemo } from 'react';
import { useUserContext } from '@ion/user';

function buildTrackFunction(eventName, userData) {
  return (eventData = {}) => {
    if (!__mrTracking) {
      return;
    }

    __mrTracking.track(eventName, {
      orgName: userData.org,
      userEmail: userData.email,
      ...eventData,
    });
  };
}

export default function useTrackWithUser(eventName) {
  const { user, currentOrg } = useUserContext();
  const userData = useMemo(() => ({ email: user?.email, org: currentOrg?.display_name }), [user, currentOrg]);

  return [buildTrackFunction(eventName, userData)];
}
