import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import useValidators from '../../hooks/use-validators';

import s from './input-number.module.scss';

const MrInputNumber = ({
  onChange,
  onBlur,
  onInput,
  disabled,
  placeholder,
  value,
  label,
  name,
  errorMsg,
  step,
  min,
  max,
  validators,
  readOnly,
  className,
  labelPosition = 'left',
  fullWidth,
  helperText,
  testId = 'MrInputNumber',
}) => {
  const { uuid } = useValidators({ validators });

  const attrs = {
    type: 'number',
    className: errorMsg && s.hasError,
    id: uuid,
    disabled,
    placeholder,
    onBlur,
    name,
    readOnly,
    onInput,
    step,
    min,
    max,
  };

  return (
    <div className={cx(s.input, fullWidth && s.fullWidth, className)} data-test-id={testId}>
      {label && labelPosition === 'left' && (
        <label className={s.labelLeft} htmlFor={uuid}>
          {label}
        </label>
      )}

      {label && labelPosition === 'top' && (
        <div>
          <label className={s.labelTop} htmlFor={uuid}>
            {label}
          </label>
        </div>
      )}

      <input value={value} onChange={onChange} {...attrs} />

      {helperText && <p className={s.helperText}>{helperText}</p>}

      {errorMsg && <p className={s.errorMsg}>{errorMsg}</p>}
    </div>
  );
};

MrInputNumber.propTypes = {
  disabled: pt.bool,
  placeholder: pt.oneOfType([pt.number, pt.string]),
  value: pt.oneOfType([pt.number, pt.string]), // string allows for empty default value
  label: pt.string,
  onChange: pt.func,
  onInput: pt.func,
  onBlur: pt.func,
  name: pt.string.isRequired,
  errorMsg: pt.string,
  step: pt.number,
  min: pt.number,
  max: pt.number,
  validators: pt.arrayOf(pt.func),
  readOnly: pt.bool,
  className: pt.string,
  labelPosition: pt.oneOf(['top', 'left']),
  fullWidth: pt.bool,
  helperText: pt.string,
  testId: pt.string,
};

export default MrInputNumber;
