import React from 'react';
import { Box, Button, Tooltip } from '@metarouter-private/mr-mui';
import propTypes from 'prop-types';

const DEFAULT_BUTTON_PROPS = {
  variant: 'text',
};

export default function ViewButton({ onClick, disabled, disabledLabel, children }) {
  const buttonProps = { ...DEFAULT_BUTTON_PROPS, onClick, disabled };

  if (disabled) {
    return (
      <Tooltip title={disabledLabel}>
        <Box component="span">
          <Button {...buttonProps}>{children}</Button>
        </Box>
      </Tooltip>
    );
  }

  return <Button {...buttonProps}>{children}</Button>;
}

ViewButton.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  disabledLabel: propTypes.string,
  children: propTypes.elementType,
};
