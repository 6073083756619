import React from 'react';
import propTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function EventNameCell({ eventName }) {
  return <TextCell text={eventName} mono />;
}

EventNameCell.propTypes = {
  eventName: propTypes.string.isRequired,
};
