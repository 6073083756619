import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import SchemaModal from 'app/schema-enforcement/SchemasTab/components/modal/SchemaModal';

import NewSchemaButton from './NewSchemaButton';

export default function SchemasTableActions() {
  return <ModalLauncher Launcher={NewSchemaButton} Modal={SchemaModal} />;
}
