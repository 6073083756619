import React from 'react';
import PropTypes from 'prop-types';

import EventCell from '../shared/EventCell';

const TITLE = 'Response';

export default function ResponseCell({ response }) {
  return <EventCell title={TITLE} encodedValue={response} lang="text" />;
}

ResponseCell.propTypes = {
  response: PropTypes.string.isRequired,
};
