import { useLazyQuerySchemas } from '@ion/api';

export default function useSchemasTable() {
  const [getSchemas, { data, pagination, loading }] = useLazyQuerySchemas();

  return {
    onFetchPage: getSchemas,
    data,
    pagination,
    loading,
  };
}
