import React from 'react';
import PropTypes from 'prop-types';

import TimeAgoAndDate from 'app/components/TimeAgoAndDate';

export default function TimestampCell({ timestamp }) {
  return <TimeAgoAndDate timestamp={timestamp} />;
}

TimestampCell.propTypes = {
  timestamp: PropTypes.string.isRequired,
};
