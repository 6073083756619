import actions from '../actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ id, name, writekey, clusterId }) {
  const action = actions.pipeline_created;
  const description = `${name}`;
  const debug_data = {
    pipelineId: id,
    pipelineName: name,
    writekey,
    clusterId,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
