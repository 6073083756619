import React from 'react';
import IntegrationCell from './cells/integration-cell';
import StatusCell from './cells/status-cell';
import EventNameCell from './cells/event-name-cell';
import InputEventCell from './cells/input-event-cell';
import OutputEventCell from './cells/output-event-cell';
import WritekeyCell from './cells/writekey-cell';
import RequestCell from './cells/request-cell';
import ResponseCell from './cells/response-cell';
import TimestampCell from './cells/timestamp-cell';

export default [
  {
    name: 'Integration',
    cell: row => <IntegrationCell integrationName={row.integrationName} />,
    width: '220px',
  },
  {
    name: 'Writekey',
    selector: row => <WritekeyCell writekey={row.writeKey} />,
    width: '160px',
  },
  {
    name: 'Status',
    cell: row => <StatusCell status={row.status} />,
    width: '120px',
  },
  {
    name: 'Event Name',
    selector: row => <EventNameCell eventName={row.normalizedEventName} />,
    width: '200px',
  },
  {
    name: 'Input Event',
    selector: row => row.inputEvent,
    cell: row => <InputEventCell inputEvent={row.inputEvent} />,
    width: '110px',
  },
  {
    name: 'Output Event',
    selector: row => <OutputEventCell outputEvent={row.outputEvent} />,
    width: '110px',
  },
  {
    name: 'Request',
    selector: row => <RequestCell requestHeaders={row.requestHeaders} requestBody={row.requestBody} />,
    width: '110px',
  },
  {
    name: 'Response',
    selector: row => <ResponseCell response={row.response} />,
    width: '110px',
  },
  {
    name: 'Timestamp',
    selector: row => <TimestampCell timestamp={row.timestamp} />,
    allowOverflow: true,
  },
];
