import React from 'react';

import { Edit03Icon, PlusCircleIcon } from '@metarouter-private/mr-mui';

import CreateSchemaForm from 'app/schema-enforcement/SchemasTab/create/CreateSchemaForm';
import EditSchemaForm from 'app/schema-enforcement/SchemasTab/edit/EditSchemaForm';

import createSubmitLabel from 'app/schema-enforcement/SchemasTab/fixtures/create/SubmitLabel';
import createTitle from 'app/schema-enforcement/SchemasTab/fixtures/create/Title';
import editSubmitLabel from 'app/schema-enforcement/SchemasTab/fixtures/edit/SubmitLabel';
import editTitle from 'app/schema-enforcement/SchemasTab/fixtures/edit/Title';

export const SchemaModalOptions = {
  create: 'create',
  edit: 'edit',
};

export const SchemaModalProps = {
  [SchemaModalOptions.create]: {
    children: <CreateSchemaForm />,
    submitLabel: createSubmitLabel,
    TitleIcon: PlusCircleIcon,
    title: createTitle,
  },
  [SchemaModalOptions.edit]: {
    children: <EditSchemaForm />,
    submitLabel: editSubmitLabel,
    TitleIcon: Edit03Icon,
    title: editTitle,
  },
};

export const isValidSchemaModalOption = modalOption => {
  switch (modalOption) {
    case SchemaModalOptions.create:
      return true;
    case SchemaModalOptions.edit:
      return true;
    default:
      throw new Error(`Unknown modal option ${modalOption}`);
  }
};

export default SchemaModalOptions;
