import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ schemaId, name, schemaDescription, jsonSchema }) {
  const action = actions.schema_created;
  const description = `${name}`;
  const debug_data = {
    schemaId,
    name,
    description: schemaDescription,
    jsonSchema,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
