import React from 'react';
import { MrCodeEditor } from '@ion/components';
import { ROWS } from 'app/schema-enforcement/SchemasTab/fixtures/code-editor';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';
import { useValidateSchema } from 'app/schema-enforcement/SchemasTab/hooks/useValidateSchema';
export default function SchemaCodeEditor() {
  const { error, jsonSchema, setJsonSchema } = useSchemaContext();
  const validateJson = useValidateSchema();

  return (
    <MrCodeEditor
      name="JSON Schema"
      lang="json"
      rows={ROWS}
      value={jsonSchema}
      onChange={event => {
        const updatedSchema = event.target.value;
        setJsonSchema(updatedSchema);
        validateJson(updatedSchema);
      }}
      errorMsg={error}
    />
  );
}
