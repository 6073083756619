import invariant from 'tiny-invariant';

export default function buildLimitAndOffset({ page, perPage }) {
  invariant(page > 0, `page must be greater than 0, given '${page}'`);

  const offset = (page - 1) * perPage;
  return {
    limit: perPage,
    offset,
  };
}
