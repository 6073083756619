import React from 'react';
import propTypes from 'prop-types';
import TextCell from 'app/components/data-table/TextCell';

export default function LatestVersionCell({ versions }) {
  const version = versions[0].version;
  const text = `Version ${version}`;
  return <TextCell text={text} />;
}

LatestVersionCell.propTypes = {
  versions: propTypes.array.isRequired,
};
