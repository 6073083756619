const STATUSES = {
  STATUS_DELIVERED: 'Delivered',
  STATUS_ERROR: 'Error',
  STATUS_FILTERED: 'Filtered',
  STATUS_PENDING: 'Pending',
};

const DEFAULT_CHIP_STYLES = {
  size: 'small',
  variant: 'soft',
};

const CHIP_STYLES = {
  STATUS_DELIVERED: {
    color: 'success',
    ...DEFAULT_CHIP_STYLES,
  },
  STATUS_ERROR: {
    color: 'error',
    ...DEFAULT_CHIP_STYLES,
  },
  STATUS_FILTERED: {
    color: 'secondary',
    ...DEFAULT_CHIP_STYLES,
  },
  STATUS_PENDING: {
    color: 'warning',
    ...DEFAULT_CHIP_STYLES,
  },
};

export function chipProps(status) {
  if (!STATUSES[status]) {
    throw new Error(`Unable to determine chip prop: ${status}`);
  }

  return {
    label: STATUSES[status],
    ...CHIP_STYLES[status],
  };
}
