import React from 'react';
import PropTypes from 'prop-types';
import TextCell from 'app/components/data-table/TextCell';

export default function DescriptionCell({ description }) {
  return <TextCell text={description} />;
}

DescriptionCell.propTypes = {
  description: PropTypes.string,
};
