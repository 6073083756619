import React from 'react';
import pt from 'prop-types';

import { ModalLauncher, Stack } from '@metarouter-private/mr-mui';

import SchemaModal from 'app/schema-enforcement/SchemasTab/components/modal/SchemaModal';

import EditSchemaButton from './EditSchemaButton';
import DeleteSchemaVersionButton from './DeleteSchemaVersionButton';

const stackProps = {
  alignItems: 'center',
  direction: 'row',
  spacing: 2,
};

export default function ActionsCell({ row }) {
  const modalProps = {
    description: row.description,
    name: row.name,
    id: row.id,
    schemaVersions: row.schemaVersions,
  };

  return (
    <Stack {...stackProps}>
      <ModalLauncher modalProps={modalProps} Launcher={EditSchemaButton} Modal={SchemaModal} />
      <DeleteSchemaVersionButton row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: pt.shape({
    description: pt.string,
    id: pt.string,
    name: pt.string,
    schemaVersions: pt.arrayOf(
      pt.shape({
        id: pt.string,
        jsonSchema: pt.string,
        schemaId: pt.string,
        version: pt.number,
      })
    ),
  }).isRequired,
};
