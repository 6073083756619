import React, { createContext, useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import YAML from 'yaml';

const PlaybookFiltersContext = createContext();

export default function PlaybookFiltersContextProvider({ children, filtersValue, filtersPath }) {
  const yamlFilters = YAML.stringify(filtersValue);
  const [value, setValue] = useState(yamlFilters);

  useEffect(() => {
    setValue(yamlFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yamlFilters]);

  return (
    <PlaybookFiltersContext.Provider
      value={{
        value,
        setValue,
        filters: filtersValue,
        filtersPath,
      }}
    >
      {children}
    </PlaybookFiltersContext.Provider>
  );
}

export function usePlaybookFiltersContext() {
  return useContext(PlaybookFiltersContext);
}

PlaybookFiltersContextProvider.propTypes = {
  children: propTypes.node.isRequired,
  filtersValue: propTypes.object,
  filtersPath: propTypes.string,
};
