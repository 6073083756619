import React from 'react';
import PropTypes from 'prop-types';
import RequestDrawer from './RequestDrawer';
import isEmpty from 'lodash/isEmpty';
import DrawerCell from 'app/event-monitoring/event-debugger/event-debugger-table/cells/shared/DrawerCell';

const DISABLED_LABEL = 'No Request';

export default function RequestCell({ requestHeaders, requestBody }) {
  const disabled = isEmpty(requestHeaders) && isEmpty(requestBody);

  const DrawerProps = {
    requestHeaders: requestHeaders,
    requestBody: requestBody,
  };

  return (
    <DrawerCell Drawer={RequestDrawer} DrawerProps={DrawerProps} disabled={disabled} disabledLabel={DISABLED_LABEL} />
  );
}

RequestCell.propTypes = {
  requestHeaders: PropTypes.string.isRequired,
  requestBody: PropTypes.string.isRequired,
};
