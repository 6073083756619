import React from 'react';
import pt from 'prop-types';
import { Select } from '@metarouter-private/mr-mui';
import useClusterSelect from './useClusterSelect';

export default function ClusterSelect(props) {
  const selectProps = useClusterSelect(props);
  return <Select {...selectProps} />;
}

ClusterSelect.propTypes = {
  defaultValue: pt.string,
  onChange: pt.func.isRequired,
  onOpen: pt.func,
  testId: pt.string,
};
