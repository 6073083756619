export const alertStyles = {
  position: 'absolute',
  zIndex: 100,
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  top: 5,
  width: '33%',
  justifyContent: 'center',
  paddingTop: 0,
  paddingBottom: 0,
};
