import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function EventNameCell({ name }) {
  return <TextCell text={name} />;
}

EventNameCell.propTypes = {
  name: PropTypes.string,
};
