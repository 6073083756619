import React from 'react';
import propTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function WritekeyCell({ writekey }) {
  return <TextCell text={writekey} mono />;
}

WritekeyCell.propTypes = {
  writekey: propTypes.string.isRequired,
};
