import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from './is-feature-enabled';

export default function useFeatureEnabled(...flagNames) {
  const featureFlags = useFlags();
  return flagNames.reduce((result, flagName) => {
    result[`${flagName}Enabled`] = isFeatureEnabled({ featureFlags, flagName });
    return result;
  }, {});
}
