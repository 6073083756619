import React from 'react';
import propTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';
import isAllowedToDeleteSchemas from '@ion/api/schemas/permissions/isAllowedToDeleteSchemas';
import { useSchemasConfirmDeleteContext } from 'app/schema-enforcement/SchemasTab/context/SchemasConfirmDeleteContextProvider';

export default function DeleteSchemaVersionButton({ row }) {
  const { onOpen } = useSchemasConfirmDeleteContext();
  const hasPermission = isAllowedToDeleteSchemas();

  return (
    <IconButton disabled={!hasPermission} data-test-id="deleteSchemaVersionButton" onClick={() => onOpen(row)}>
      <Trash02Icon />
    </IconButton>
  );
}

DeleteSchemaVersionButton.propTypes = {
  row: propTypes.object.isRequired,
};
