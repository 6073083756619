import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Box, ChevronDownIcon } from '@metarouter-private/mr-mui';
import { accordionSummaryClasses } from '@mui/material/AccordionSummary';

export default function ExpandIconWrapper({ isExpanded, onClick, title }) {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        color: (theme.vars || theme).palette.text.primary,
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
        ...(isExpanded && {
          [`&.${accordionSummaryClasses.expanded}`]: {
            transform: 'rotate(180deg)',
          },
        }),
      })}
      className={cx(accordionSummaryClasses.expandIconWrapper, isExpanded && accordionSummaryClasses.expanded)}
      onClick={onClick}
    >
      <ChevronDownIcon title={title} />
    </Box>
  );
}

ExpandIconWrapper.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};
