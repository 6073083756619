import { onError } from '@apollo/client/link/error';
import { notify } from '../../index';
import getUserControlProxyErrors from './getUserControlProxyErrors';
import displayDefaultGraphQLErrorMessage from './displayDefaultGraphQLErrorMessage';
import errors from './errors';
import { captureException } from '@sentry/browser';

const onErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const method = operation.variables?.method;
  const path = operation.variables?.path;
  const writekey = operation.variables?.writekey;
  const mutation = operation.operationName;
  const mutationError = errors.graphQL[operation.operationName];
  if (graphQLErrors !== undefined && graphQLErrors.length > 0) {
    const graphQLError = graphQLErrors[0];
    let message = graphQLError.message;

    captureException(new Error(message));

    try {
      message = JSON.parse(message);
    } catch (e) {
      // message is not JSON, do nothing
    }

    switch (graphQLErrors[0]?.extensions?.code) {
      case 'invalid-jwt':
        // This message comes from Hasura
        if (message === 'Could not verify JWT: JWTExpired') {
          notify.error({
            title: errors.graphQL.expiredJWT.title,
            message: errors.graphQL.expiredJWT.message,
            button: {
              title: 'Refresh',
              onClick: () => {
                window.location.reload();
              },
            },
          });
        } else {
          notify.error({
            title: errors.graphQL.invalidJWT.title,
            message: errors.graphQL.invalidJWT.message,
          });
        }
        break;
      case 'permission-denied':
        notify.error({
          title: errors.graphQL.permissionDenied.title,
          message: errors.graphQL.permissionDenied.message,
        });
        break;
      case 'validation-failed':
        notify.error({
          title: errors.graphQL.validationFailed.title,
          message: errors.graphQL.validationFailed.message,
        });
        break;
      case 'unexpected':
        if (mutation === 'useControlProxy') {
          if (method && method.toUpperCase() === 'GET' && path && path.endsWith('/pipeline')) break;
          if (method && method.toUpperCase() === 'GET' && path && path.endsWith('/event_traces')) break;
          if (method && method.toUpperCase() === 'POST' && path && path.endsWith('/pipeline/diff')) break;
          if (method && method.toUpperCase() === 'POST' && path && path.endsWith('/pipeline')) break;
          if (method && method.toUpperCase() === 'POST' && path && path.endsWith('/test/validate_integration')) break;
          notify.error({
            title: getUserControlProxyErrors(method, path, writekey).title,
            message: getUserControlProxyErrors(method, path, writekey).message,
            code: message,
          });
          return;
        }

        if (mutation === 'useDashboardProxy') {
          return;
        }

        if (mutationError !== undefined) {
          notify.error({
            title: mutationError.title,
            message: mutationError.message,
          });
          return;
        }
        displayDefaultGraphQLErrorMessage();
        break;
      default:
        displayDefaultGraphQLErrorMessage();
    }
  }
  if (networkError) {
    captureException(networkError);
    notify.error({
      title: errors.networkIssues.title,
      message: errors.networkIssues.message,
    });
  }
});

export default onErrorLink;
