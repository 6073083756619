import { useEffect, useState } from 'react';
import YAML from 'yaml';
import { isNull as _isNull, isEqual as _isEqual } from 'lodash';

import { usePlaybookFiltersContext } from './context/playbook-filters-context';
import determineErrorMessage from './yaml-code-editor/determine-error-message';
import { isAllowedToUpdateIntegration } from '@ion/api/integrations/update/is-allowed-to-update-integration';

export default function useDisabledSubmitButton() {
  const [disabled, setDisabled] = useState(true);
  const { value, filters } = usePlaybookFiltersContext();
  const isAllowed = isAllowedToUpdateIntegration();

  useEffect(() => {
    if (!isAllowed) {
      return;
    }
    const errorMsg = determineErrorMessage(value);
    if (_isNull(errorMsg)) {
      const hasChanges = !_isEqual(filters, YAML.parse(value));
      if (hasChanges) {
        setDisabled(false);
        return;
      }
    }
    setDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, filters, isAllowed]);

  return disabled;
}
