import React from 'react';
import EventCell from '../shared/EventCell';
import PropTypes from 'prop-types';

const TITLE = 'Input Event';

export default function InputEventCell({ inputEvent }) {
  return <EventCell title={TITLE} encodedValue={inputEvent} />;
}

InputEventCell.propTypes = {
  inputEvent: PropTypes.string.isRequired,
};
