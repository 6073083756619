import React from 'react';

import { MrAppLayout, MrHeaderTabs, MrTab, MrTabList, MrTabPanel } from '@ion/components';

import { routes } from 'app/constants';
import MrTabPanelBase from 'app/components/MrTabPanelBase';
import AppHeader from 'app/components/app-header';
import SideNav from 'app/components/side-nav';
import RedirectFeatureGate from 'app/launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import EventsTab from './EventsTab';
import SchemasTab from './SchemasTab';

export default function SchemaEnforcementPage() {
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <MrAppLayout
        title="Schema Enforcement"
        header={<AppHeader icon="schemas" title="Schema Enforcement" route={routes.schemaEnforcement} />}
        sideNav={<SideNav />}
      >
        <MrHeaderTabs>
          <MrTabList>
            <MrTab>Events</MrTab>
            <MrTab>Schemas</MrTab>
          </MrTabList>

          <MrTabPanel>
            <MrTabPanelBase>
              <EventsTab />
            </MrTabPanelBase>
          </MrTabPanel>

          <MrTabPanel>
            <MrTabPanelBase>
              <SchemasTab />
            </MrTabPanelBase>
          </MrTabPanel>
        </MrHeaderTabs>
      </MrAppLayout>
    </RedirectFeatureGate>
  );
}
