import YAML from 'yaml';
import { startsWith as _startsWith, isNull as _isNull } from 'lodash';
import { isValidYaml } from '@ion/mrdash';
import { PLAYBOOK_STANZA } from '../../../../constants';

export const ERROR_MESSAGES = {
  INVALID_YAML: 'Invalid Yaml',
  FILTERS_PLACEHOLDER:
    'The Filters section cannot be left empty. If you do not want to apply any filters, enter filters: []',
};

export default function determineErrorMessage(value) {
  if (!isValidYaml(value)) {
    return ERROR_MESSAGES.INVALID_YAML;
  }

  if (!_startsWith(value, PLAYBOOK_STANZA.FILTERS)) {
    return ERROR_MESSAGES.FILTERS_PLACEHOLDER;
  }

  const ymlFilters = YAML.parse(value)[PLAYBOOK_STANZA.FILTERS];

  if (_isNull(ymlFilters)) {
    return ERROR_MESSAGES.FILTERS_PLACEHOLDER;
  }

  return null;
}
