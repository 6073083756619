import { useMutation, useTrackWithUser } from '@ion/api/index';
import permission from '@ion/api/Apollo/permissions';
import { GET_SCHEMAS } from '@ion/api/schemas/schemas-query-builder';
import {
  mutation as updateSchemaMutation,
  buildVariables as buildUpdateSchemaVariables,
} from './update-schema-mutation-builder';
import {
  mutation as schemaUpdatedMutation,
  buildVariables as buildAuditLogVariables,
} from '@ion/api/audit-logs/schema-updated/audit-log-mutation-builder';
import mergeQueries from '@ion/api/Apollo/merge-queries';

export const combinedMutation = mergeQueries('UpdateSchemaAndAudit', [updateSchemaMutation, schemaUpdatedMutation]);

export default function useUpdateSchema(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_edited');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [GET_SCHEMAS],
    context: { permission: permission.updateSchemas },
  });

  const updateSchemaMutation = ({ name, description, jsonSchema, schemaId, version }) => {
    return executeMutation({
      variables: {
        ...buildUpdateSchemaVariables({
          name,
          description,
          jsonSchema,
          schemaId,
          version,
        }),
        ...buildAuditLogVariables({ schemaId, name, description, jsonSchema, version }),
      },
    }).then(response => {
      if (!response.errors) {
        trackWithUser({ id: schemaId, name, description, version });
      }
      return response;
    });
  };

  return [updateSchemaMutation, response];
}
