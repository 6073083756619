import React from 'react';

import { MrCodeEditor } from '@ion/components';

import useYamlCodeEditorProps from './use-yaml-code-editor-props';

export default function MrYamlCodeEditor(props) {
  const yamlCodeEditorProps = useYamlCodeEditorProps(props);
  return <MrCodeEditor {...yamlCodeEditorProps} />;
}
