import React from 'react';

import { afterOrgSwitch } from '@ion/app/src/after-org-switch';
import { Alert, Stack, Button, Typography } from '@metarouter-private/mr-mui';
import { useUserContext } from '@ion/user/context/UserContext';
import { useRemoveOverrideOrganization } from '@ion/api/';
import { alertStyles } from './alert-styles';

const OrgOverrideBanner = () => {
  const [removeOverrideOrganization] = useRemoveOverrideOrganization({
    onCompleted: () => {
      afterOrgSwitch();
    },
  });
  const { currentOrg, isOrgOverride } = useUserContext();

  if (!isOrgOverride) return <></>;

  return (
    <Alert variant="filled" severity="error" icon={false} sx={alertStyles}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography>Organization Override in Effect for {currentOrg.display_name}</Typography>
        <Button
          onClick={() => {
            removeOverrideOrganization();
          }}
          sx={{ border: 'solid' }}
          color="error"
        >
          REMOVE
        </Button>
      </Stack>
    </Alert>
  );
};

export default OrgOverrideBanner;
