import React from 'react';
import propTypes from 'prop-types';

import TimeAgoAndDate from 'app/components/TimeAgoAndDate';

export default function Created({ createdAt }) {
  return <TimeAgoAndDate timestamp={createdAt} />;
}

Created.propTypes = {
  createdAt: propTypes.string,
};
