import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';

import { ConfirmDelete } from '@metarouter-private/mr-mui';

import useSchemasConfirmDeleteProvider from './useSchemasConfirmDeleteProvider';

const SchemasConfirmDeleteContext = createContext(null);

export default function SchemasConfirmDeleteContextProvider({ children }) {
  const { confirmDeleteProps, onOpen } = useSchemasConfirmDeleteProvider();

  return (
    <SchemasConfirmDeleteContext.Provider value={{ confirmDeleteProps, onOpen }}>
      {children}
      <ConfirmDelete {...confirmDeleteProps} />
    </SchemasConfirmDeleteContext.Provider>
  );
}

export function useSchemasConfirmDeleteContext() {
  return useContext(SchemasConfirmDeleteContext);
}

SchemasConfirmDeleteContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
