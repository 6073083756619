import React from 'react';
import propTypes from 'prop-types';

import { FormModal } from '@metarouter-private/mr-mui';
import { useInsertSchema, useUpdateSchema } from '@ion/api';

import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';
import { SchemaModalOptions } from 'app/schema-enforcement/SchemasTab/context/SchemaModalOptions';

export default function SchemaModal({ open, onClose }) {
  const [createSchema] = useInsertSchema({ onCompleted: onClose });

  const [editSchema] = useUpdateSchema({ onCompleted: onClose });

  const { schemaFormModalProps, modalOption, name, description, jsonSchema, schemaId, version } = useSchemaContext();

  const formModalProps = {
    open,
    onClose,
    submitLabel: '',
    ...schemaFormModalProps,
    onSubmit: () => {
      if (modalOption === SchemaModalOptions.create) {
        createSchema({
          name,
          description,
          jsonSchema,
        });
      }
      if (modalOption === SchemaModalOptions.edit) {
        editSchema({
          name,
          description,
          jsonSchema,
          schemaId,
          version: version + 1,
        });
      }
    },
  };

  return <FormModal {...formModalProps} />;
}

SchemaModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};
