import { PLAYBOOK, PLAYBOOK_PARAMETER, PLAYBOOK_STANZA } from '../../../constants';
import { indexOf as _indexOf, values as _values, get as _get, union as _union } from 'lodash';

export const ERROR_MESSAGES = {
  NOT_VALID_SELECTED_SECTION: 'Not valid selectedSection',
  SELECTED_EVENT_UNDEFINED: 'SelectedEvent cannot be undefined',
};

export default function getFiltersAndPath({ selectedSection, selectedEvent }, { playbook }) {
  const navSections = _union(_values(PLAYBOOK), _values(PLAYBOOK_PARAMETER));

  if (_indexOf(navSections, selectedSection) === -1) {
    throw new Error(ERROR_MESSAGES.NOT_VALID_SELECTED_SECTION);
  }

  const isEventSelected = selectedSection === PLAYBOOK.EVENT_SPECIFIC;

  if (isEventSelected && !selectedEvent) {
    throw new Error(ERROR_MESSAGES.SELECTED_EVENT_UNDEFINED);
  }

  const filtersPath = isEventSelected ? `${selectedSection}.${selectedEvent}` : `${selectedSection}`;
  const filters = _get(playbook, `${filtersPath}.${PLAYBOOK_STANZA.FILTERS}`);

  return {
    filtersPath,
    filters,
  };
}
