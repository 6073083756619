import { gql } from '@apollo/client';

export default gql`
  fragment schemaVersion on schema_versions {
    id
    version
    jsonSchema
    schemaId
  }
`;
