import React from 'react';
import PropTypes from 'prop-types';

import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import isAllowedToCreateSchemas from '@ion/api/schemas/permissions/isAllowedToCreateSchemas';

const TITLE = 'New Event';

export default function NewEventButton({ onClick }) {
  const hasPermission = isAllowedToCreateSchemas();

  return (
    <Button startIcon={<PlusCircleIcon />} size="large" disabled={!hasPermission} onClick={onClick}>
      {TITLE}
    </Button>
  );
}

NewEventButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
