import { useMemo } from 'react';
import { generateId } from '../index';
import useValidatorLookup from './use-validator-lookup';

export default function useValidators({ validators }) {
  const uuid = useMemo(() => generateId(), []);
  const { addValidators } = useValidatorLookup({ uuid });

  if (validators) {
    addValidators({ uuid, validators });
  }

  return {
    uuid,
  };
}
