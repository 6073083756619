import React from 'react';
import propTypes from 'prop-types';

import EventCell from '../shared/EventCell';

const TITLE = 'Output Event';

export default function OutputEventCell({ outputEvent }) {
  return <EventCell title={TITLE} encodedValue={outputEvent} />;
}

OutputEventCell.propTypes = {
  outputEvent: propTypes.string.isRequired,
};
