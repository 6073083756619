import React from 'react';
import propTypes from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';

import TextCell from 'app/components/data-table/TextCell';

export default function StarterKitVersion({ starterkitVersion }) {
  if (starterkitVersion === '0' || _isEmpty(starterkitVersion)) {
    return <TextCell variant="body2" />;
  }

  return <TextCell variant="body2" text={`v${starterkitVersion}`} />;
}

StarterKitVersion.propTypes = {
  starterkitVersion: propTypes.string,
};
