export const DEFAULT_STACK_PROPS = {
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  spacing: 2,
};

const DEFAULT_BUTTON_PROPS = {
  size: 'large',
};

export const CANCEL_BUTTON_PROPS = {
  key: 'cancel',
  variant: 'text',
  color: 'secondary',
  ...DEFAULT_BUTTON_PROPS,
};

export const SUBMIT_BUTTON_PROPS = {
  variant: 'contained',
  color: 'primary',
  ...DEFAULT_BUTTON_PROPS,
};
