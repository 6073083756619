import { get as _get, keys as _keys, isEmpty as _isEmpty } from 'lodash';
import { PLAYBOOK, PLAYBOOK_STANZA } from '../../constants';

export default function getOuterFilters({ playbook }) {
  const globalFilters = _get(playbook[PLAYBOOK.GLOBAL], PLAYBOOK_STANZA.FILTERS);
  const defaultFilters = _get(playbook[PLAYBOOK.DEFAULT], PLAYBOOK_STANZA.FILTERS);
  const events = _keys(playbook[PLAYBOOK.EVENT_SPECIFIC]);
  const allEventFilters = events.reduce((allFilters, event) => {
    const eventFilter = _get(playbook[PLAYBOOK.EVENT_SPECIFIC], `${event}.${PLAYBOOK_STANZA.FILTERS}`);
    if (!_isEmpty(eventFilter)) {
      allFilters.push(eventFilter);
    }
    return allFilters;
  }, []);

  return {
    globalFilters,
    defaultFilters,
    allEventFilters,
  };
}
