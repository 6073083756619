import React from 'react';

import pt from 'prop-types';

import s from 'app/event-monitoring/event-schemas/panel/details.module.scss';
import { MrInlineIconCard } from '@ion/components';

const ReportDetails = ({ details, timeframeHours }) => {
  return (
    <div className={s.container}>
      <MrInlineIconCard className={s.inlineIconCard} description={'Last seen'}>
        <div> {details.lastSeen} </div>
      </MrInlineIconCard>
      <MrInlineIconCard className={s.inlineIconCard} description={`Sample Count (last ${timeframeHours} hours)`}>
        <div> {details.count} </div>
      </MrInlineIconCard>
      <MrInlineIconCard className={s.inlineIconCard} description={'Unique schema combinations'}>
        <div> {details.combinations}</div>
      </MrInlineIconCard>
    </div>
  );
};

ReportDetails.propTypes = {
  details: pt.shape({
    lastSeen: pt.string,
    combinations: pt.string,
    count: pt.string,
  }).isRequired,
  timeframeHours: pt.number.isRequired,
};

export default ReportDetails;
