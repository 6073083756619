import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';

import { Tooltip, Box, Typography } from '@metarouter-private/mr-mui';

import FeatureGate from '../../../launch-darkly/gates/feature-gate';
import { PLAYBOOK_FILTERS_FLAG } from '../playbook-filters-flag';

const tooltipProps = {
  title: 'Filter(s) applied',
  arrow: true,
  placement: 'top',
  'data-test-id': 'FiltersTooltip',
};

const typographyProps = {
  color: 'warning.dark',
  variant: 'body2',
};

const boxProps = {
  component: 'span',
};

export default function FiltersIndicator({ filters }) {
  if (_isEmpty(filters)) {
    return null;
  }

  return (
    <FeatureGate flagName={PLAYBOOK_FILTERS_FLAG}>
      <Tooltip {...tooltipProps}>
        <Box {...boxProps}>
          <Typography {...typographyProps}>&#9679;</Typography>
        </Box>
      </Tooltip>
    </FeatureGate>
  );
}

FiltersIndicator.propTypes = {
  filters: PropTypes.array,
};
