import {
  keys as _keys,
  has as _has,
  pick as _pick,
  upperFirst as _upperFirst,
  camelCase as _camelCase,
  isEmpty as _isEmpty,
} from 'lodash';
import castType from '@ion/app/src/pipelines/cast-type';
import { generateId } from '@ion/components';
import { BIG_QUERY_OPTIONS, BIG_QUERY_OPTIONS_UNSPECIFIED } from '../consts';

/**
 *
 * Generates the calculated form based on the following check downs
 * 1. If set from the form state use it there
 * 2. If not in form state the playbook state
 * 3. fallback to default value
 *
 * @param {{ formFields: object, operationData: object }}
 * @returns {{
 *  selectedOperation:string,
 *  selectedSubOperation:string,
 *  isPipelineVar:boolean,
 *  pipelineVarId:string,
 *  expressionName:string,
 *  outputKey:string,
 *  inputKey:string,
 *  eventValue:string,
 *  separator:string,
 *  prefix:string,
 *  depth:number,
 *  replace:string,
 *  replaceWith:string,
 *  joinSeparator:string,
 *  start:number,
 *  end:number,
 *  splitSeparator:string,
 *  maxElements:number,
 *  toHash:string,
 *  extractKey:string,
 *  precision:number,
 *  body:string,
 *  lang:string,
 *  uuid:string,
 *  format:string,
 *  date:string,
 *  directValue:string,
 *  directType:string,
 *  defaultType:string,
 *  defaultValue:string,
 *  applyDefault:boolean
 * }}
 */
const buildFormFromParameterData = (formState, operationData) => {
  const formOutput = buildStandardFormFields(formState, operationData);

  switch (formOutput.operation) {
    case 'mappings':
      return handleMapping(formState, operationData, formOutput);
    case 'expressions':
      return handleExpression(formState, operationData, formOutput);
    case 'spreadings':
      return handleSpreading(formState, operationData, formOutput);
    case 'enrichments':
      return handleEnrichment(formState, operationData, formOutput);
    case 'params':
      return handleParam(formState, operationData, formOutput);
    default:
      return formOutput;
  }
};

const buildStandardFormFields = (formState, operationData) => {
  const [section, subSection] = operationData._path.split('.');

  const operation =
    formState[getFormFieldName('operation', operationData)]?.value ?? getOperation(subSection, operationData) ?? '';
  const event = formState[getFormFieldName('event', operationData)]?.value ?? operationData.event ?? null;
  const opIndex = formState[getFormFieldName('opIndex', operationData)]?.value ?? operationData.opIndex;
  const opId = formState[getFormFieldName('opId', operationData)]?.value ?? operationData.opId;

  const subOperation =
    formState[getFormFieldName('subOperation', operationData)]?.value ??
    getSubOperationFromPlaybook(operation, subSection, operationData, formState) ??
    getSubOperationDefault(operation);
  const _path = `${section}.${operation}`;

  return {
    event,
    operation,
    subOperation,
    opId,
    _path,
    opIndex,
  };
};

const handleMapping = (formState, operationData, formOutput) => {
  formOutput = getDefaultsFromMappings(formState, operationData, formOutput);
  formOutput.outputKey =
    formState[getFormFieldName('outputKey', operationData)]?.value ?? operationData.outputKey ?? '';
  formOutput.inputKey = formState[getFormFieldName('inputKey', operationData)]?.value ?? operationData.inputKey ?? '';
  //handle specific subOperation mapping fields
  switch (formOutput.subOperation) {
    case 'replaceString':
      return handleReplaceString(formState, operationData, formOutput);
    case 'substring':
      return handleSubstring(formState, operationData, formOutput);
    case 'splitString':
      return handleSplitString(formState, operationData, formOutput);
    case 'truncateFloat':
      return handleTruncateFloat(formState, operationData, formOutput);
    case 'toHash':
      return handleToHash(formState, operationData, formOutput);
    case 'pluckValues':
      return handlePluckValues(formState, operationData, formOutput);
    case 'joinValues':
      return handleJoinValues(formState, operationData, formOutput);
    case 'singleKey':
      return handleSingleKeyExpression(formState, operationData, formOutput);
    case 'toDateTime':
      return handleToDateTime(formState, operationData, formOutput);
    default:
      return formOutput;
  }
};

const handleToDateTime = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const inputFormat =
    formState[getFormFieldName('inputFormat', operationData)]?.value ?? transform?.toDateTime?.inputFormat ?? '';
  const outputFormat =
    formState[getFormFieldName('outputFormat', operationData)]?.value ?? transform?.toDateTime?.outputFormat ?? '';
  return {
    ...formOutput,
    inputFormat,
    outputFormat,
  };
};

const handleReplaceString = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const replace =
    formState[getFormFieldName('replace', operationData)]?.value ?? transform?.replaceString?.replace ?? '';
  const replaceWith =
    formState[getFormFieldName('replaceWith', operationData)]?.value ?? transform?.replaceString?.with ?? '';
  return {
    ...formOutput,
    replace,
    replaceWith,
  };
};

const handleSubstring = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const start = formState[getFormFieldName('start', operationData)]?.value ?? transform?.substring?.start ?? 0;
  const end = formState[getFormFieldName('end', operationData)]?.value ?? transform?.substring?.end ?? 1;
  return {
    ...formOutput,
    end: castType(end),
    start: castType(start),
  };
};

const handleSplitString = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const separator =
    formState[getFormFieldName('splitSeparator', operationData)]?.value ?? transform?.splitString?.separator ?? '';
  const maxElements =
    formState[getFormFieldName('maxElements', operationData)]?.value ?? transform?.splitString?.maxElements ?? 1;
  return {
    ...formOutput,
    splitSeparator: separator,
    maxElements: castType(maxElements),
  };
};

const handleTruncateFloat = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const precision =
    formState[getFormFieldName('precision', operationData)]?.value ?? transform?.truncateFloat?.precision ?? 0;
  return {
    ...formOutput,
    precision: castType(precision),
  };
};

const handleToHash = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const toHash = formState[getFormFieldName('toHash', operationData)]?.value ?? transform?.toHash ?? 'md5';
  return {
    ...formOutput,
    toHash,
  };
};

const handlePluckValues = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const extractKey =
    formState[getFormFieldName('extractKey', operationData)]?.value ?? transform?.pluckValues?.extractKey ?? '';
  return {
    ...formOutput,
    extractKey,
  };
};

const handleJoinValues = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  const joinSeparator =
    formState[getFormFieldName('joinSeparator', operationData)]?.value ?? transform?.joinValues?.separator ?? '';
  return {
    ...formOutput,
    joinSeparator,
  };
};

const getDefaultsFromMappings = (formState, operationData, formOutput) => {
  // empty object and array don't have values so no need to look up
  const typeLookup = {
    boolean: 'defaultBool',
    number: 'defaultFloat',
    integer: 'defaultInt',
    string: 'defaultString',
  };
  //TODO check to make sure its cool if we do all of these defaults
  const getDefaultType = operationData => {
    if (operationData.defaultJson) {
      //TODO check to see if the returns correctly for both object and
      return operationData.defaultJson;
    } else if (operationData.defaultBool !== undefined) {
      return 'boolean';
    } else if (operationData.defaultFloat !== undefined) {
      return 'number';
    } else if (operationData.defaultInt !== undefined) {
      return 'integer';
    } else if (operationData.defaultString !== undefined) {
      return 'string';
    }

    return null;
  };

  const defaultType =
    formState[getFormFieldName('defaultType', operationData)]?.value ?? getDefaultType(operationData) ?? 'string';
  const defaultValue =
    formState[getFormFieldName('defaultValue', operationData)]?.value ?? operationData[typeLookup[defaultType]] ?? '';
  const applyDefault =
    formState[getFormFieldName('applyDefault', operationData)]?.value ?? getDefaultType(operationData) !== null;

  return {
    ...formOutput,
    ...(defaultType && { defaultType }),
    ...(defaultType && { defaultValue }),
    applyDefault: castType(applyDefault),
  };
};

const handleExpression = (formState, operationData, formOutput) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  formOutput.body =
    formState[getFormFieldName('body', operationData)]?.value ??
    transform?.expression?.body ??
    operationData.body ??
    '';
  formOutput.lang =
    formState[getFormFieldName('lang', operationData)]?.value ??
    transform?.expression?.lang ??
    operationData.lang ??
    'lua';

  switch (formOutput.subOperation) {
    case 'singleKey':
      return handleSingleKeyExpression(formState, operationData, formOutput);
    case 'multiKey':
      return handleMultiKeyExpression(formState, operationData, formOutput);
    default:
      return formOutput;
  }
};

const handleSingleKeyExpression = (formState, operationData, formOutput) => {
  const section = operationData._path.split('.')[0];
  const outputKey = formState[getFormFieldName('outputKey', operationData)]?.value ?? operationData.outputKey ?? '';
  const inputKey = formState[getFormFieldName('inputKey', operationData)]?.value ?? operationData.inputKey ?? 'input';

  return {
    opId: formOutput.opId,
    opIndex: formOutput.opIndex,
    _path: `${section}.mappings`,
    event: formOutput.event,
    outputKey,
    inputKey,
    operation: 'expressions',
    body: formOutput.body ?? '',
    lang: formOutput.lang ?? 'lua',
    subOperation: 'singleKey',
  };
};

const handleMultiKeyExpression = (formState, operationData, formOutput) => {
  const expressionName =
    formState[getFormFieldName('expressionName', operationData)]?.value ?? operationData.expressionName ?? '';
  return {
    ...formOutput,
    expressionName,
  };
};

const handleEnrichment = (formState, operationData, formOutput) => {
  //Standard Fields across all enrichments
  formOutput.outputKey =
    formState[getFormFieldName('outputKey', operationData)]?.value ?? operationData.outputKey ?? '';

  //handle specific subOperation enrichment fields
  switch (formOutput.subOperation) {
    case 'uuid':
      return handleUuidEnrichment(formState, operationData, formOutput);
    case 'timestamp':
      return handleTimestampEnrichment(formState, operationData, formOutput);
    case 'date':
      return handleDateEnrichment(formState, operationData, formOutput);
    case 'directValue':
      return handleDirectValueEnrichment(formState, operationData, formOutput);
    default:
      return formOutput;
  }
};

const handleUuidEnrichment = (formState, operationData, formOutput) => {
  const uuid = formState[getFormFieldName('uuid', operationData)]?.value ?? operationData.uuid ?? 'v4';
  return {
    ...formOutput,
    uuid,
  };
};

const handleTimestampEnrichment = (formState, operationData, formOutput) => {
  const timestamp =
    formState[getFormFieldName('timestamp', operationData)]?.value ?? operationData.timestamp ?? 'seconds';
  return {
    ...formOutput,
    timestamp,
  };
};

const handleDateEnrichment = (formState, operationData, formOutput) => {
  const date = formState[getFormFieldName('date', operationData)]?.value ?? operationData.date?.format ?? '';
  return {
    ...formOutput,
    date,
  };
};

const handleDirectValueEnrichment = (formState, operationData, formOutput) => {
  //Setting up all of the important defaults
  const findDirectValueKey = ({ directKeys, operationData }) => {
    return directKeys.find(k => _has(operationData, k));
  };
  //TODO this might make more sense in the visual component
  const directValueOptions = {
    staticBool: 'boolean',
    staticFloat: 'number',
    staticInt: 'integer',
    staticString: 'string',
  };
  const directKey = findDirectValueKey({ operationData, directKeys: _keys(directValueOptions) });
  const directValue =
    formState[getFormFieldName('directValue', operationData)]?.value ?? operationData[directKey]?.toString() ?? '';
  const directType =
    formState[getFormFieldName('directType', operationData)]?.value ?? directValueOptions[directKey] ?? 'string';
  return {
    ...formOutput,
    directValueOptions,
    directValue,
    directType,
  };
};

const handleSpreading = (formState, operationData, formOutput) => {
  const inputKey = formState[getFormFieldName('inputKey', operationData)]?.value ?? operationData.inputKey ?? '';
  const expressionName =
    formState[getFormFieldName('expressionName', operationData)]?.value ?? operationData.expressionName ?? '';
  const separator =
    formState[getFormFieldName('separator', operationData)]?.value ?? operationData.flatten?.separator ?? '';
  const prefix = formState[getFormFieldName('prefix', operationData)]?.value ?? operationData.flatten?.prefix ?? '';
  const depth = formState[getFormFieldName('depth', operationData)]?.value ?? operationData.flatten?.depth ?? 0;

  return {
    ...formOutput,
    separator,
    prefix,
    depth: parseInt(depth),
    expressionName,
    inputKey,
  };
};

function handleBigQueryOptions(formState, operationData, formOutput) {
  const defaultValue =
    formState[getFormFieldName('defaultValue', operationData)]?.value ??
    operationData?.defaultValue?.[0] ??
    BIG_QUERY_OPTIONS_UNSPECIFIED;
  return {
    ...formOutput,
    ...{ defaultValue },
  };
}

const handleParam = (formState, operationData, formOutput) => {
  const name = operationData.name;

  formOutput.name = name;
  formOutput.isOptional = operationData.isOptional;
  formOutput.operation = formState[getFormFieldName('operation', operationData)]?.value ?? 'params';

  const exampleValue = operationData.exampleValue;
  const type = operationData.type;
  const defaultValue =
    formState[getFormFieldName('defaultValue', operationData)]?.value ?? operationData.defaultValue ?? '';
  const pipelineVar =
    castType(formState[getFormFieldName('pipelineVar', operationData)]?.value) ??
    Boolean(operationData.pipelineVarId) ??
    false;
  const pipelineVarId =
    formState[getFormFieldName('pipelineVarId', operationData)]?.value ?? operationData.pipelineVarId ?? '';

  switch (name) {
    case 'AUTHENTICATION (SASL)':
      return handleSasl(formState, operationData, formOutput);
    case 'S_3':
      return handleS3(formState, operationData, formOutput);
    case 'GCS':
      return handleGcs(formState, operationData, formOutput);
    case 'BROKERS':
      return handleBrokers(formState, operationData, formOutput);
    case 'STAGE':
      return handleStage(formState, operationData, formOutput);
    case BIG_QUERY_OPTIONS:
      return handleBigQueryOptions(formState, operationData, formOutput);
    default:
      return {
        ...formOutput,
        exampleValue,
        ...(defaultValue && { defaultValue: defaultValue }),
        type,
        pipelineVar,
        ...(pipelineVar && (pipelineVarId ? { pipelineVarId: pipelineVarId } : { pipelineVarId: generateId() })),
      };
  }
};

const handleBrokers = (formState, operationData, formOutput) => {
  const defaultValue =
    formState[getFormFieldName('defaultValue', operationData)]?.value ?? operationData?.defaultValue?.join(',') ?? '';
  return {
    ...formOutput,
    ...(defaultValue && { defaultValue: defaultValue }),
  };
};

const handleS3 = (formState, operationData, formOutput) => {
  const region =
    formState[getFormFieldName('region', operationData)]?.value ?? operationData?.defaultValue?.region ?? '';
  const bucket =
    formState[getFormFieldName('bucket', operationData)]?.value ?? operationData?.defaultValue?.bucket ?? '';
  const prefix =
    formState[getFormFieldName('prefix', operationData)]?.value ?? operationData?.defaultValue?.prefix ?? '';
  const accessKey =
    formState[getFormFieldName('accessKey', operationData)]?.value ?? operationData?.defaultValue?.accessKey ?? '';
  const secretKey =
    formState[getFormFieldName('secretKey', operationData)]?.value ?? operationData?.defaultValue?.secretKey ?? '';
  const serverSideEncryption =
    formState[getFormFieldName('serverSideEncryption', operationData)]?.value ??
    operationData?.defaultValue.serverSideEncryption ??
    '';
  const compression =
    formState[getFormFieldName('compression', operationData)]?.value ??
    operationData?.defaultValue?.compression?.toString() ??
    '';

  return {
    ...formOutput,
    region,
    bucket,
    prefix,
    accessKey,
    secretKey,
    serverSideEncryption,
    compression,
  };
};

const handleGcs = (formState, operationData, formOutput) => {
  const bucket =
    formState[getFormFieldName('bucket', operationData)]?.value ?? operationData?.defaultValue?.bucket ?? '';
  const prefix =
    formState[getFormFieldName('prefix', operationData)]?.value ?? operationData?.defaultValue?.prefix ?? '';
  const credentialJson =
    formState[getFormFieldName('credentialJson', operationData)]?.value ??
    operationData?.defaultValue?.credentialJson?.toString() ??
    '';
  const compression =
    formState[getFormFieldName('compression', operationData)]?.value ??
    operationData?.defaultValue?.compression?.toString() ??
    '';

  return {
    ...formOutput,
    bucket,
    prefix,
    credentialJson,
    compression,
  };
};

const handleSasl = (formState, operationData, formOutput) => {
  const saslType =
    formState[getFormFieldName('saslType', operationData)]?.value ?? operationData?.defaultValue?.saslType ?? '';
  const password =
    formState[getFormFieldName('password', operationData)]?.value ?? operationData?.defaultValue?.password ?? '';
  const username =
    formState[getFormFieldName('username', operationData)]?.value ?? operationData?.defaultValue?.username ?? '';
  const algorithm =
    formState[getFormFieldName('algorithm', operationData)]?.value ??
    operationData?.defaultValue?.algorithm?.toString() ??
    '2';

  const scram = saslType === 'SCRAM';

  return {
    ...formOutput,
    saslType,
    password,
    username,
    ...(scram && { algorithm: algorithm }),
  };
};

const handleStage = (formState, operationData, formOutput) => {
  const stageType =
    formState[getFormFieldName('stageType', operationData)]?.value ?? operationData?.defaultValue?.stageType ?? '';

  const isGcs = stageType === 'GCS';
  const oneOfFormValue = isGcs
    ? handleGcs(formState, operationData, formOutput)
    : handleS3(formState, operationData, formOutput);

  return {
    ...formOutput,
    stageType,
    ...oneOfFormValue,
  };
};

const getFormFieldName = (fieldName, operationData) => {
  return `${operationData.opId}-${fieldName}-${operationData._path}`;
};

/**
 *
 * This kind of reverse engineers what the sub operation is
 * based on the playbook data
 * TODO this feels like a anti-pattern here I think operationData should contain this information
 * TODO will this work with singleKey expression (I think so)
 * @param {{ formFields: object, operationData: object }}
 * @returns {string}
 */
const getSubOperationFromPlaybook = (operation, subSection, operationData, formState) => {
  const subOperationState = formState[getFormFieldName('subOperation', operationData)]?.value;

  // Use case: switches type -> handles this in getSubOperationDefault()
  if (operation !== subSection) {
    return undefined;
  }

  // Use case: singleKey exists and the user selects `mappings`
  // Had to check the empty formState, as well so that it only sets getSubOperationDefault() when there is a form change
  if (operation === 'mappings' && subOperationState === undefined && !_isEmpty(formState)) {
    return undefined;
  }

  const transform = operationData.transforms?.length && operationData.transforms[0];
  const transformName = transform && _keys(transform)[0];
  const isMultiKey = subSection === 'expressions' && !transformName;
  const isSpreading = subSection === 'spreadings';

  if (isMultiKey) {
    return 'multiKey';
  }

  if (!transformName && isSpreading) {
    return 'flatten';
  }

  // Enrichment Type
  if (!transform && !transformName) {
    if (_keys(operationData).some(k => k.startsWith('static')) || operationData.pipelineVarId) {
      return 'directValue';
    }

    const enrichmentKeys = ['uuid', 'timestamp', 'date']; // only one of these keys will be present on a given operation
    const enrichment = _pick(operationData, enrichmentKeys);
    return _keys(enrichment)[0];
  }

  // Mapping Type
  switch (transformName) {
    case 'toScalar':
      return getToScalarKey(transform);
    case 'modifyString':
      return getModifyStringKey(transform);
    case 'expression':
      return 'singleKey';
    default:
      return transformName;
  }
};

const getToScalarKey = transform => {
  // concat 'toScalar' with the value of the toScalar key
  return `toScalar${_upperFirst(_camelCase(transform.toScalar))}`;
};

/**
 *
 * Convert playbook's modifyString key/value structure to a camelcase string that matches dropdown values
 *
 * @return {string}
 */
const getModifyStringKey = transform => {
  const { modifyString } = transform;
  return `modifyString${_upperFirst(_camelCase(modifyString))}`;
};

const getSubOperationDefault = selectedOperation => {
  switch (selectedOperation) {
    case 'enrichments':
      return 'directValue';
    case 'mappings':
      return 'directAssignment';
    case 'spreadings':
      return 'flatten';
    case 'expressions':
      return 'singleKey';
    default:
      return '';
  }
};

const getOperation = (subSection, operationData) => {
  const transform = operationData.transforms?.length && operationData.transforms[0];
  return subSection === 'mappings' && transform?.expression ? 'expressions' : subSection;
};

export default buildFormFromParameterData;
