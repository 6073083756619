import React from 'react';

import NameCell from './cells/NameCell';
import LatestVersionCell from './cells/LatestVersionCell';
import DescriptionCell from './cells/DescriptionCell';
import AssignedTo from './cells/AssignedToCell';
import ActionsCell from './cells/ActionsCell';

export const COLUMNS = [
  {
    name: 'Name',
    cell: row => <NameCell name={row.name} />,
  },
  {
    name: 'Latest Version',
    cell: row => <LatestVersionCell versions={row.schemaVersions} />,
  },
  {
    name: 'Description',
    cell: row => <DescriptionCell description={row.description} />,
  },
  {
    name: 'Assigned to',
    cell: row => <AssignedTo pipelines={row.pipelines} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
