import { useMemo } from 'react';
import determineErrorMessage from './determine-error-message';

const DEFAULT_PROPS = {
  lang: 'yaml',
  rows: 30,
  testId: 'YamlCodeEditor',
};

export default function useYamlCodeEditorProps({ value, ...rest }) {
  const errorMsg = useMemo(() => determineErrorMessage(value), [value]);

  return {
    ...DEFAULT_PROPS,
    ...rest,
    value,
    errorMsg,
  };
}
