import React from 'react';
import { Stack } from '@metarouter-private/mr-mui';
import { useTrackPage } from '@ion/api';

import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import EventDebuggerPageContextProvider from './context/EventDebuggerPageContextProvider';
import EventDebuggerPageHeader from './header';
import EventDebuggerTable from './event-debugger-table/EventDebuggerTable';

const EventDebuggerPage = () => {
  useTrackPage('event_debugger_page_view');

  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.eventDebuggerMvp}>
      <EventDebuggerPageContextProvider>
        <Stack spacing={2}>
          <EventDebuggerPageHeader />
          <EventDebuggerTable />
        </Stack>
      </EventDebuggerPageContextProvider>
    </RedirectFeatureGate>
  );
};

export default EventDebuggerPage;
