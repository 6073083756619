import React from 'react';
import propTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function NameCell({ name }) {
  return <TextCell text={name} />;
}

NameCell.propTypes = {
  name: propTypes.string.isRequired,
};
