import React from 'react';

import NewEventButton from './NewEventButton';

export default function EventsTableActions() {
  const onClick = () => {
    console.log('NewEventButton clicked');
  };
  return <NewEventButton onClick={onClick} />;
}
