import { useEffect, useState } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import { useGetEventTraces } from '@ion/api';
import { useEventDebuggerContext } from '../../context/EventDebuggerPageContextProvider';
import eventDebuggerTableColumns from '../event-debugger-table-columns';

const DEFAULTS = {
  pagination: true,
  columns: eventDebuggerTableColumns,
};

export function useEventDebuggerTable() {
  const [tableData, setTableData] = useState([]);
  const { selectedCluster, refreshedAt } = useEventDebuggerContext();
  const [getEventTraces, { loading }] = useGetEventTraces({
    onCompleted: response => {
      setTableData(response.traces);
    },
    onError: () => {
      setTableData([]);
    },
  });

  useEffect(() => {
    if (!_isEmpty(selectedCluster)) {
      getEventTraces({ clusterId: selectedCluster, limit: 100 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCluster, refreshedAt]);

  return {
    ...DEFAULTS,
    loading,
    paginationResetDefaultPage: loading,
    ...buildNoDataComponent({ selectedCluster }),
    data: tableData,
  };
}

const MAKE_SELECTION = 'Please make a selection';

function buildNoDataComponent({ selectedCluster }) {
  if (selectedCluster) {
    return;
  }
  return { noDataComponent: MAKE_SELECTION };
}
