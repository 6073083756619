import React from 'react';
import propTypes from 'prop-types';

import { validateRequired } from '../form/validators';
import useValidators from '../../hooks/use-validators';

export default function InputRadioGroupInput({ name, value, required }) {
  const validators = required ? [validateRequired()] : undefined;
  const { uuid: id } = useValidators({ validators });

  return <input type="hidden" id={id} name={name} value={value} />;
}

InputRadioGroupInput.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.string,
  required: propTypes.any,
};
