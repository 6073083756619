import useLazyQuery from '../hooks/useLazyQuery';
import permission from '../Apollo/permissions';
import { buildVariables, GET_EVENT_NAMES } from './event-names-query-builder';

export default function useLazyQueryEventNames({ ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_EVENT_NAMES, {
    context: { permission: permission.readEventNames },
    // Not using the cache so we can dynamically refresh whenever new schema created.
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const getSchemas = pagination => {
    const variables = buildVariables(pagination);
    return executeQuery({ variables });
  };

  return [getSchemas, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      data: [],
      pagination: { count: null },
    };
  }

  const { event_names, event_names_aggregate } = data;

  return {
    data: event_names,
    pagination: { count: event_names_aggregate.aggregate.count },
  };
}
