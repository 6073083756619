import React from 'react';
import pt from 'prop-types';

import { MrForm, useForm, MrInputText, formValidators, MrInputSelectDeprecated } from '@ion/components';
import { Button, Stack } from '@metarouter-private/mr-mui';

import s from './index.module.scss';
import { SUBMIT_BUTTON_PROPS } from '../parameter/default-button-props';

const { validateRequired, validateSnakeCase, validatePattern } = formValidators;

const DEFAULT_STACK_PROPS = {
  direction: 'row',
  alignItems: 'self-end',
  justifyContent: 'flex-start',
  spacing: 2,
};

const NewEventForm = ({ navState, playbookState, isAllowed }) => {
  const formId = `newEventForm`;
  const { formState, validateField, resetError, submitForm, setField } = useForm(formId);
  const name = `eventName-${navState.selectedEvent}`;
  const cloneFrom = `eventName-${navState.selectedEvent}-cloneFrom`;

  const onFormSubmit = () => {
    const { data, isValid } = submitForm();
    if (isValid) {
      playbookState.createEvent(data[name], data[cloneFrom]);
      navState.setEvent(data[name]);
    }
  };

  return (
    <MrForm id={formId} onSubmit={onFormSubmit} className={s.newEvent}>
      <div className={s.eventHeader}>
        <p className={s.inputHint}>What is the incoming event_name from your Analytics.js event stream?</p>

        <Stack {...DEFAULT_STACK_PROPS}>
          <MrInputText
            onChange={setField}
            value={formState[name]?.value ?? ''}
            name={name}
            label="event name"
            errorMsg={formState[name]?.errorMsg}
            onBlur={validateField}
            onInput={resetError}
            validators={[
              validateRequired(),
              validateSnakeCase('Event names must be lowercase in snake case format (e.g. "test_event_123")'),
              validatePattern(/^[_a-zA-Z0-9]+$/, 'Only alphanumeric characters and _ are allowed'),
            ]}
            labelPosition="top"
            className={s.textField}
            testId="eventName"
          />
          <MrInputSelectDeprecated
            name={cloneFrom}
            options={playbookState.getSortedEvents() || []}
            value={formState[cloneFrom]?.value ?? ''}
            onChange={event => {
              setField(event);
            }}
            onBlur={validateField}
            errorMsg={formState[cloneFrom]?.errorMsg}
            placeholder
            isClearable
            label="clone from (optional)"
            labelPosition="top"
            dropdownPosition="right"
            testId="cloneFrom"
          />
          <Button disabled={!isAllowed} {...SUBMIT_BUTTON_PROPS} type="submit">
            Add Event
          </Button>
        </Stack>
      </div>
    </MrForm>
  );
};

NewEventForm.propTypes = {
  playbookState: pt.shape({
    getSortedEvents: pt.func.isRequired,
    createEvent: pt.func.isRequired,
  }).isRequired,
  className: pt.string,
  navState: pt.shape({
    selectedEvent: pt.string,
    setEvent: pt.func.isRequired,
  }).isRequired,
  isAllowed: pt.bool.isRequired,
};

export default NewEventForm;
