import React from 'react';
import propTypes from 'prop-types';

import { isEmpty as _isEmpty } from 'lodash';

import { Typography } from '@metarouter-private/mr-mui';

export default function TextCell({ text, ...typographyProps }) {
  let content = text;

  if (_isEmpty(text)) {
    content = '-';
  }

  return (
    <Typography component="span" title={content} {...typographyProps}>
      {content}
    </Typography>
  );
}

TextCell.propTypes = {
  text: propTypes.string,
};
