import React from 'react';
import pt from 'prop-types';
import { Select } from '@metarouter-private/mr-mui';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';

export default function SchemaVersionSelect({ schemaVersions }) {
  const { onChangeVersion } = useSchemaContext();

  const options = schemaVersions.map(schemaVersion => ({
    label: `Version ${schemaVersion.version}`,
    value: schemaVersion.id,
  }));

  return (
    <Select
      defaultValue={schemaVersions[0].id}
      label="Schema Version"
      options={options}
      onChange={event => {
        const schemaVersion = event.target.value;
        onChangeVersion(schemaVersion);
      }}
    />
  );
}

SchemaVersionSelect.propTypes = {
  schemaVersions: pt.array,
};
