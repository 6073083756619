import parseJWT from './parseJWT';

const hasuraClaims = 'https://hasura.io/jwt/claims';

let permissions = {};

const setToken = sessionToken => {
  const token = (sessionToken !== null && sessionToken.length) > 0 ? parseJWT(sessionToken) : {};
  permissions = token[hasuraClaims]?.permissions || {};
};

const isAllowed = permission => {
  return !!getRole(permission);
};

const getRole = permission => {
  return permissions?.[permission];
};

export { setToken, isAllowed, getRole };
