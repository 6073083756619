import React from 'react';
import cx from 'classnames';
import pt from 'prop-types';
import { get as _get } from 'lodash';

import { MrH } from '@ion/components';
import s from './index.module.scss';
import { PLAYBOOK, PLAYBOOK_STANZA } from '../../constants';
import FiltersSideNav from './FiltersSideNav';
import FiltersIndicator from './FiltersIndicator';
import getOuterFilters from './get-outer-filters';

const ParametersSideNav = ({ navState, playbookState }) => {
  const sortedEvents = playbookState.getSortedEvents();

  //TODO need to refactor scroll to
  const scrollTo = ({ opId }) => {
    navState.setTransformation(opId);
    // setExpandedCard({ opId, scrollTo: true })
  };

  const getNavText = ({ outputKey, expressionName, name }) => {
    if (outputKey) {
      return outputKey;
    }
    if (name) return name;

    if (expressionName?.length > 0) {
      return expressionName;
    }

    // placeholder for when no nav text is available, such as when changing to / from multi-key expression
    return '---';
  };

  const { globalFilters, defaultFilters, allEventFilters } = getOuterFilters(playbookState, sortedEvents);

  return (
    <nav className={s.nav}>
      <div onClick={() => navState.setSection('params', sortedEvents)} className={s.header}>
        <MrH h="h4" sans>
          1. Parameters
        </MrH>
      </div>
      {navState.selectedSection === 'params' && (
        <ul>
          {playbookState.getOperationsFromSection('params').map(({ opId, name, isOptional }) => {
            return (
              <li
                className={cx(s.ellipsis, opId === navState.selectedTransformation ? s.current : null)}
                key={opId}
                onClick={() => {
                  scrollTo({ opId });
                }}
              >
                <div>
                  {getNavText({ name })}
                  {!isOptional && <span className={s.required}>*</span>}
                </div>
              </li>
            );
          })}
        </ul>
      )}

      <div
        onClick={() => navState.setSection('global', sortedEvents)}
        className={s.header}
        data-test-id="globalOuterSideNav"
      >
        <MrH h="h4" sans>
          2. Global
        </MrH>
        <FiltersIndicator filters={globalFilters} />
      </div>

      {navState.selectedSection === PLAYBOOK.GLOBAL && (
        <ul>
          <FiltersSideNav
            selectedTransformation={navState.selectedTransformation}
            filtersPath={PLAYBOOK.GLOBAL}
            filters={globalFilters}
            scrollTo={scrollTo}
          />
          {playbookState.getOperationsFromSection('global').map(({ outputKey, expressionName, opId, name }) => {
            return (
              <li
                className={cx(s.ellipsis, opId === navState.selectedTransformation ? s.current : null)}
                key={opId}
                onClick={() => {
                  scrollTo({ opId });
                }}
              >
                {getNavText({ outputKey, expressionName, name })}
              </li>
            );
          })}
        </ul>
      )}

      <div
        className={s.header}
        onClick={() => navState.setSection('eventSpecific', sortedEvents)}
        data-test-id="eventOuterSideNav"
      >
        <MrH h="h4" sans>
          3. Event Specific
        </MrH>
        <FiltersIndicator filters={allEventFilters} />
      </div>

      {navState.selectedSection === PLAYBOOK.EVENT_SPECIFIC && (
        <ul>
          {sortedEvents.map(event => (
            <li
              key={event}
              className={cx(s.ellipsis, navState.selectedEvent === event ? s.current : null)}
              onClick={() => navState.setEvent(event)}
              data-test-id={`${event}InnerSideNav`}
            >
              <div>
                {event}
                <FiltersIndicator
                  filters={_get(playbookState.playbook[PLAYBOOK.EVENT_SPECIFIC], `${event}.${PLAYBOOK_STANZA.FILTERS}`)}
                />
              </div>
            </li>
          ))}
          <li
            className={'newEvent' === navState.selectedEvent ? s.current : null}
            onClick={() => navState.setEvent('newEvent')}
          >
            New Event
          </li>
        </ul>
      )}

      <div
        className={s.header}
        onClick={() => navState.setSection('default', sortedEvents)}
        data-test-id="defaultOuterSideNav"
      >
        <MrH h="h4" sans>
          4. Default
        </MrH>
        <FiltersIndicator filters={defaultFilters} />
      </div>

      {navState.selectedSection === PLAYBOOK.DEFAULT && (
        <ul>
          <FiltersSideNav filtersPath={PLAYBOOK.DEFAULT} filters={defaultFilters} scrollTo={scrollTo} />
          {playbookState.getOperationsFromSection('default').map(({ outputKey, expressionName, opId }) => {
            return (
              <li
                className={cx(s.ellipsis, opId === navState.selectedTransformation ? s.current : null)}
                key={opId}
                onClick={() => {
                  scrollTo({ opId });
                }}
              >
                {getNavText({ outputKey, expressionName })}
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};

ParametersSideNav.propTypes = {
  navState: pt.shape({
    selectedSection: pt.string.isRequired,
    selectedEvent: pt.string,
    selectedTransformation: pt.string,
    setTransformation: pt.func,
    setEvent: pt.func,
    setSection: pt.func,
  }).isRequired,
  playbookState: pt.object.isRequired,
};

export default ParametersSideNav;
