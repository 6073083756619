import React from 'react';
import pt from 'prop-types';
import { Edit03Icon, IconButton } from '@metarouter-private/mr-mui';

import isAllowedToUpdateSchemas from '@ion/api/schemas/permissions/isAllowedToUpdateSchemas';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';
import { SchemaModalOptions } from 'app/schema-enforcement/SchemasTab/context/SchemaModalOptions';

export default function EditSchemaButton({ modalProps, onClick }) {
  const {
    onClear,
    setName,
    setDescription,
    setJsonSchema,
    setSchemaId,
    setSchemaVersions,
    setVersion,
    setSchemaFormModalOption,
  } = useSchemaContext();
  const hasPermission = isAllowedToUpdateSchemas();
  const handleOnClick = () => {
    onClear();
    setName(modalProps.name);
    setDescription(modalProps.description);
    setJsonSchema(modalProps.schemaVersions[0].jsonSchema);
    setSchemaId(modalProps.id);
    setVersion(modalProps.schemaVersions[0].version);
    setSchemaVersions(modalProps.schemaVersions);
    setSchemaFormModalOption(SchemaModalOptions.edit);
    onClick();
  };
  return (
    <IconButton data-test-id={'editSchemaButton'} disabled={!hasPermission} onClick={handleOnClick}>
      <Edit03Icon />
    </IconButton>
  );
}

EditSchemaButton.propTypes = {
  modalProps: pt.shape({
    description: pt.string,
    id: pt.string,
    name: pt.string,
    schemaVersions: pt.arrayOf(
      pt.shape({
        id: pt.string,
        jsonSchema: pt.string,
        schemaId: pt.string,
        version: pt.number,
      })
    ),
  }).isRequired,
  onClick: pt.func.isRequired,
};
