import React, { useState, useRef } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { MrLogo, MrSvg, MrText } from '@ion/components';

import { routes } from 'app/constants';

import s from './index.module.scss';
import NavLink from './nav-link';

import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from 'app/launch-darkly/is-feature-enabled';
import NavLinkSection from './nav-link-section';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';

const midLinks = [
  { name: 'dashboard', section: 'monitoring', routes: [routes.dashboard] },
  {
    name: 'eventMonitoring',
    section: 'monitoring',
    routes: [routes.eventMonitoring],
  },
  {
    name: 'pipelines',
    section: 'management',
    routes: [routes.pipelines, routes.newPipeline, routes.pipeline, routes.analyticsJSBuilder],
  },
  {
    name: 'integrations',
    section: 'management',
    routes: [routes.integrations, routes.newIntegration, routes.editPlaybook],
  },
  { name: 'clusters', section: 'management', routes: [routes.clusters, routes.newCluster] },
  { name: 'settings', section: 'admin', routes: [routes.settings] },
  {
    name: 'schemaEnforcement',
    section: 'admin',
    routes: [routes.schemaEnforcement],
    featureFlag: FEATURE_FLAGS.intendedSchemaMvp,
  },
  { name: 'auditLog', section: 'admin', routes: [routes.auditLog] },
];

export function buildEnabledMidLinks(featureFlags, links = midLinks) {
  return links.filter(link => {
    return !link.featureFlag || isFeatureEnabled({ featureFlags, flagName: link.featureFlag });
  });
}

const bottomLinks = (label = false) => (
  <div className={s.bottom}>
    <NavLink name="help" href={`https://docs.metarouter.io`} label={label} />
    <NavLink name="logOut" label={label} />
  </div>
);

const SideNav = ({ styleNames, className }) => {
  const cursor = useRef();
  const [disable, setDisable] = useState(true);
  const history = useHistory();

  const featureFlags = useFlags();
  const enabledMidLinks = buildEnabledMidLinks(featureFlags);

  const isActive = routes => (match, location) => {
    return routes.some(path => path === location.pathname);
  };

  return (
    <div className={s.navContainer}>
      <nav
        /*
          This disables hover effect by default (to prevent it reopening after click),
          and then reenables on mousemove (with  buffer) and onleave
        */
        onMouseLeave={() => setDisable()}
        onMouseMove={e => {
          const y = e.clientY;
          if (!cursor.current) {
            cursor.current = y;
          } else if (Math.abs(cursor.current - y) > 40) {
            setDisable();
          }
        }}
        className={cx(
          s.nav,
          !disable && s.enabledHover,
          styleNames?.split(' ').map(c => s[c]),
          className
        )}
      >
        <div className={s.navEdge}></div>
        <div className={s.main}>
          <div className={s.top}>
            <div className={s.logoContent}>
              <MrLogo className={s.logo} onClick={() => history.push(routes.pipelines)} />
            </div>
            <div className={s.mid}>
              <MrText className={s.navSection}>Monitoring</MrText>
              <NavLinkSection links={enabledMidLinks} section="monitoring" type="icon" isActive={isActive} />
              <MrText className={s.navSection}>Management</MrText>
              <NavLinkSection links={enabledMidLinks} section="management" type="icon" isActive={isActive} />
              <MrText className={s.navSection}>Admin</MrText>
              <NavLinkSection links={enabledMidLinks} section="admin" type="icon" isActive={isActive} />
            </div>
          </div>
          {bottomLinks()}
        </div>
        <div className={s.sub}>
          <div className={s.top}>
            <div className={s.logoTextContent}>
              <MrSvg id="metarouter-logotype-gray" />
            </div>
            <div className={s.mid}>
              <MrText className={s.navSection}>&nbsp;</MrText>
              <NavLinkSection links={enabledMidLinks} section="monitoring" type="label" isActive={isActive} />
              <MrText className={s.navSection}>&nbsp;</MrText>
              <NavLinkSection links={enabledMidLinks} section="management" type="label" isActive={isActive} />
              <MrText className={s.navSection}>&nbsp;</MrText>
              <NavLinkSection links={enabledMidLinks} section="admin" type="label" isActive={isActive} />
            </div>
          </div>
          {bottomLinks(true)}
        </div>
      </nav>
    </div>
  );
};

SideNav.propTypes = {
  className: pt.string,
  styleNames: pt.string,
};

export default SideNav;
