import React from 'react';

import GetSchemaSummary from 'app/event-monitoring/event-schemas/get-schema-summary';
import Header from 'app/event-monitoring/event-schemas/header';
import ReportList from 'app/event-monitoring/event-schemas/list';
import ReportPanel from 'app/event-monitoring/event-schemas/panel';
import NewSchemaReport from 'app/event-monitoring/event-schemas/new-report';
import useEventSchemasState from 'app/event-monitoring/event-schemas/state';
import MrTabPanelBase from 'app/components/MrTabPanelBase';

import s from './header/index.module.scss';

export default function EventSchemasPage() {
  const state = useEventSchemasState();

  return (
    <>
      {!state.cluster && (
        <MrTabPanelBase>
          <div className={s.container}>
            <NewSchemaReport
              updateReportSettings={state.updateReportSettings}
              onClose={() => {}}
              cluster={state.cluster}
            />
          </div>
        </MrTabPanelBase>
      )}
      {state.cluster && (
        <MrTabPanelBase
          header={
            <Header cluster={state.cluster} timeframeHours={state.timeframeHours} resetReports={state.resetReports} />
          }
        >
          <>
            <GetSchemaSummary
              cluster={state.cluster}
              earliestSeen={state.earliestSeen}
              timeframeHours={state.timeframeHours}
              setSchemaSummary={state.setReports}
              setLoadingSummary={state.loadingReports}
            />
            <ReportList
              loading={state.isLoadingReports}
              reports={state.reports}
              setSelectedReport={state.setSelectedReport}
              selectedReport={state.selectedReport}
              sortReports={state.sortReports}
            />
            {state.selectedReport && (
              <ReportPanel
                cluster={state.cluster}
                earliestSeen={state.earliestSeen}
                timeframeHours={state.timeframeHours}
                report={state.selectedReport}
                clearReport={() => state.setSelectedReport(null)}
              />
            )}
          </>
        </MrTabPanelBase>
      )}
    </>
  );
}
