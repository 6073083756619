import { useEffect } from 'react';
import parseJWT from '@ion/api/Apollo/parseJWT';
import useUserState, { actions } from './useUserState';
import { useUserContext } from '../UserContext';

export const useLoginCypress = () => {
  const { setState } = useUserContext();
  const [state, dispatch] = useUserState();

  useEffect(() => {
    if (!state.isTokenValid) {
      const token = localStorage.getItem('mr.cypress_id_token');
      const parsed = parseJWT(token);

      dispatch({
        type: actions.SET_USER_INFO,
        payload: {
          tokenInfo: { ...parsed, __raw: token },
        },
      });
    }
  }, [state.isTokenValid, dispatch]);

  useEffect(() => {
    setState(state);
  }, [state, setState]);

  return {
    ...state,
  };
};
