import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateNotEqual } = formValidators;

const ToDateTime = ({ inputFormat, outputFormat, opId, _path }) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  return (
    <>
      {((name = `${opId}-inputFormat-${_path}`) => (
        <MrInputText
          label="inputFormat"
          onBlur={validateField}
          name={name}
          value={formState[name]?.value ?? inputFormat}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="input format"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-outputFormat-${_path}`) => (
        <MrInputText
          label="OutputFormat"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={formState[name]?.value ?? outputFormat}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateNotEqual(inputFormat, 'Values for inputFormat and outputFormat must be different')]}
          placeholder="output format"
          labelPosition="top"
        />
      ))()}
    </>
  );
};

ToDateTime.propTypes = {
  inputFormat: pt.string,
  outputFormat: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default ToDateTime;
