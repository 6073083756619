import { AUTH0_USER_ROLES } from './constants';
import sortBy from 'lodash/sortBy';

export const UI_ROLES_ORDER = {
  [AUTH0_USER_ROLES.ADMIN]: 0,
  [AUTH0_USER_ROLES.DEPLOYMENT_MANAGER]: 1,
  [AUTH0_USER_ROLES.INTEGRATION_MANAGER]: 2,
  [AUTH0_USER_ROLES.READ_ONLY]: 3,
};

export default function sortRoles(roles) {
  return sortBy(roles, role => {
    return UI_ROLES_ORDER[role.name];
  });
}
