import { useLazyQueryEventNames } from '@ion/api';

export default function useEventsTable() {
  const [getEvents, { data, pagination, loading }] = useLazyQueryEventNames();

  return {
    onFetchPage: getEvents,
    data,
    pagination,
    loading,
  };
}
