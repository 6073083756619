import { useQueryClusters } from '@ion/api';
import { useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

export default function useClusterSelect({
  defaultValue,
  onChange,
  onOpen,
  label = 'Cluster',
  testId = 'ClusterSelect',
}) {
  const { data: clustersData } = useQueryClusters();
  const [clusterId, setClusterId] = useState(defaultValue ?? '');

  const options = useMemo(
    () =>
      clustersData.map(cluster => {
        return { label: cluster.name, value: cluster.id };
      }),
    [clustersData]
  );

  function selectCluster(clusterId) {
    setClusterId(clusterId);
    onChange(clusterId);
  }

  function onChangeCluster(event) {
    selectCluster(event.target.value);
  }

  useEffect(() => {
    if (options.length === 1 && clusterId === '') {
      selectCluster(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const finalOptions = isEmpty(options) ? [{ label: 'No clusters available', value: '' }] : options;

  return {
    label,
    options: finalOptions,
    value: clusterId,
    onChange: onChangeCluster,
    ...(onOpen && { SelectProps: { onOpen } }),
    'data-test-id': testId,
  };
}
