import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ schemaId, name, description, jsonSchema, version }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      schemaId,
      name,
      schemaDescription: description,
      jsonSchema,
      version,
    }),
  };
}
