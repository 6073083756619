import s from 'app/event-monitoring/event-schemas/panel/tree/index.module.scss';

const Depth = {
  0: s.depth0,
  1: s.depth1,
  2: s.depth2,
  3: s.depth3,
  4: s.depth4,
  5: s.depth5,
  6: s.depth6,
  7: s.depth7,
  8: s.depth8,
  9: s.depth9,
};

export default Depth;
