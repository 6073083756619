import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';
import { isNull as _isNull, isEmpty as _isEmpty } from 'lodash';
import { DEFAULT_VALUES } from 'app/schema-enforcement/SchemasTab/fixtures/code-editor';

import { isValidSchemaModalOption, SchemaModalProps } from './SchemaModalOptions';

const SchemaContext = createContext(null);

export default function SchemaContextProvider({ children }) {
  const [name, setName] = React.useState(null);
  const [description, setDescription] = React.useState('');
  const [jsonSchema, setJsonSchema] = React.useState(DEFAULT_VALUES.jsonSchema);
  const [error, setError] = React.useState(null);
  const [schemaId, setSchemaId] = React.useState(null);
  const [schemaVersions, setSchemaVersions] = React.useState([]);
  const [version, setVersion] = React.useState(null);
  const [validatingJson, setValidatingJson] = React.useState(false);
  const isSubmitDisabled = _isEmpty(name) || _isNull(jsonSchema) || !_isNull(error) || validatingJson;
  const [schemaFormModalProps, setSchemaFormModalProps] = React.useState(null);
  const [modalOption, setModalOption] = React.useState(null);
  const setSchemaFormModalOption = modalOption => {
    if (isValidSchemaModalOption(modalOption)) {
      setModalOption(modalOption);
      setSchemaFormModalProps(SchemaModalProps[modalOption]);
    }
  };
  const onChangeVersion = schemaVersionId => {
    const schemaVersion = schemaVersions.find(version => version.id === schemaVersionId);
    setJsonSchema(schemaVersion.jsonSchema);
  };
  const onClear = () => {
    setName(null);
    setDescription('');
    setJsonSchema(DEFAULT_VALUES.jsonSchema);
    setError(null);
    setSchemaId(null);
    setSchemaVersions([]);
    setVersion(null);
    setValidatingJson(false);
  };

  return (
    <SchemaContext.Provider
      value={{
        name,
        setName,
        description,
        setDescription,
        error,
        setError,
        jsonSchema,
        setJsonSchema,
        schemaVersions,
        setSchemaVersions,
        schemaId,
        setSchemaId,
        version,
        setVersion,
        setValidatingJson,
        isSubmitDisabled,
        schemaFormModalProps,
        modalOption,
        setSchemaFormModalOption,
        onChangeVersion,
        onClear,
      }}
    >
      {children}
    </SchemaContext.Provider>
  );
}

export function useSchemaContext() {
  return useContext(SchemaContext);
}

SchemaContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
