import { useCallback } from 'react';
import { debounce as _debounce } from 'lodash';
import { useSchemaContext } from 'app/schema-enforcement/SchemasTab/context/SchemaContextProvider';
import { isValidJSONObject } from '@ion/mrdash';

export const useValidateSchema = () => {
  const { setError, setValidatingJson } = useSchemaContext();

  // TODO: Update this to validate JSON Schema
  // There was an issue using AJV and eval,
  // we need to look into a different solution.
  function validateSchema(schema) {
    if (!isValidJSONObject(schema)) {
      setError('Invalid JSON Object');
    }
    setValidatingJson(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidate = useCallback(
    _debounce(event => {
      validateSchema(event);
    }, 2000),
    []
  );

  const handleChange = event => {
    setError(null);
    setValidatingJson(true);
    debouncedValidate(event);
  };

  return handleChange;
};
