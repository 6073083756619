import React from 'react';
import propTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function OverviewTableRow({ firstCell, secondCell }) {
  return (
    <tr>
      <td>
        <TextCell text={firstCell} variant="body2" />
      </td>
      <td>{secondCell}</td>
    </tr>
  );
}

OverviewTableRow.propTypes = {
  firstCell: propTypes.string,
  secondCell: propTypes.node,
};
