import { gql } from '@apollo/client';

export const removeSchemaFragment = gql`
  fragment removeSchema on schemas {
    id
  }
`;

export const mutation = gql`
  mutation RemoveSchema($schemas_pk_columns: schemas_pk_columns_input!) {
    update_schemas_by_pk(pk_columns: $schemas_pk_columns, _set: { deletedAt: "now()" }) {
      ...removeSchema
    }
  }
  ${removeSchemaFragment}
`;

export function buildVariables({ id }) {
  return {
    schemas_pk_columns: {
      id,
    },
  };
}
