import React, { useCallback, useEffect, useState } from 'react';
import pt from 'prop-types';

import { isUndefined as _isUndefined, isEmpty as _isEmpty, isNil as _isNil } from 'lodash';

import { MrGroupedDataTable } from '@ion/components';
import s from './index.module.scss';

import useDashboardMetrics from './use-dashboard-metrics';

import useBuildColumns from './columns/use-build-columns';

import { useDashboardProxy } from '@ion/api';
import NO_DATA_COMPONENT_MESSAGE from '../no-data-component-message';
import useAppMainHeight from 'app/shared/layout/hooks/useAppMainHeight';

const DashboardDataTable = ({
  metricsData,
  clusters,
  pipelines,
  setMetrics,
  loadingMetrics,
  setError,
  isLoading,
  setEventMetrics,
  setEventMetricsError,
  eventMetricsError,
  since,
  until,
  expandedRows,
  setExpandedRows,
  integrations,
  noDataComponentMessage,
  integrationIds,
  groupBy,
  configuredClusters,
  filterCount,
  defaultFilter,
}) => {
  const { mainTableColumns, subTableColumns } = useBuildColumns({ groupBy });
  useDashboardMetrics(
    clusters,
    pipelines,
    setMetrics,
    setError,
    loadingMetrics,
    since,
    until,
    integrations,
    integrationIds,
    groupBy,
    filterCount,
    defaultFilter
  );

  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedEventMetrics, setExpandedEventMetrics] = useState(null);
  const appMainHeight = useAppMainHeight();
  const [getEventMetrics] = useDashboardProxy({
    onCompleted: r => {
      if (!r || _isEmpty(r)) {
        setEventMetrics(selectedRow, [], []);
        setEventMetricsError(selectedRow, 'NO_DATA_FOUND');
      } else {
        setExpandedEventMetrics(r);
      }
    },
    onError: () => {
      setEventMetrics(selectedRow, [], []);
      setEventMetricsError(selectedRow, 'GRAPHQL_QUERY_ERROR');
    },
  });

  const [getFilteredEventMetrics] = useDashboardProxy({
    onCompleted: r => {
      if (!r || _isEmpty(r)) {
        setEventMetrics(selectedRow, expandedEventMetrics, []);
      } else {
        setEventMetrics(selectedRow, expandedEventMetrics, r);
      }
    },
    onError: () => {
      setEventMetrics(selectedRow, expandedEventMetrics, []);
    },
  });

  const selectedMetric = metricsData?.find(d => d.Name === selectedRow);

  const eventMetricsQuery = {
    ...(groupBy === 'integration' &&
      selectedMetric && {
        clusters: clusters,
        pipelines: pipelines,
        integrationIds: [selectedMetric?.facet[1]],
      }),
    ...(groupBy === 'pipeline' &&
      selectedMetric && {
        clusters: configuredClusters,
        pipelines: [selectedMetric?.writeKey],
        integrationIds: integrationIds,
      }),
    dateRange: {
      since,
      until,
    },
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEventMetricsCallback = useCallback(getEventMetrics, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilteredEventCallback = useCallback(getFilteredEventMetrics, []);

  // TODO (Chris 2023.07.26) - Refactor hook along with row selection process
  useEffect(() => {
    if (
      selectedRow &&
      selectedMetric &&
      selectedMetric?.eventData?.length === 0 &&
      _isUndefined(eventMetricsError[selectedRow]) &&
      eventMetricsQuery.clusters &&
      eventMetricsQuery.pipelines
    ) {
      getEventMetricsCallback({ ...eventMetricsQuery, query: 'Event' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, selectedMetric, getEventMetricsCallback]);

  useEffect(() => {
    if (expandedEventMetrics !== null && eventMetricsQuery.clusters && eventMetricsQuery.pipelines) {
      getFilteredEventCallback({ ...eventMetricsQuery, query: 'FilteredEventsWithId' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedEventMetrics, getFilteredEventCallback]);

  useEffect(() => {
    if (_isEmpty(metricsData)) {
      setSelectedRow(null);
      setExpandedEventMetrics(null);
    }
  }, [metricsData]);

  const mainTableProps = {
    columns: mainTableColumns,
    data: metricsData,
    progressPending: _isNil(noDataComponentMessage) && isLoading,
    noDataComponent: noDataComponentMessage,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowExpanded: row => expandedRows.indexOf(row.Name) !== -1,
    onRowExpandToggled: (isOpen, row) => {
      if (isOpen) {
        setSelectedRow(row.Name);
        setExpandedRows([...expandedRows, row.Name]);
      } else {
        const filtered = expandedRows.filter(r => r !== row.Name);
        setExpandedRows([...filtered]);
      }
    },
    highlightOnHover: true,
    fixedHeader: true,
    fixedHeaderScrollHeight: appMainHeight,
  };

  const buildSubTableProps = row => {
    return {
      progressPending: row?.data?.eventData?.length === 0 && !eventMetricsError[row.data.Name],
      columns: subTableColumns,
      data: row?.data?.eventData,
      noTableHead: true,
      noDataComponent: NO_DATA_COMPONENT_MESSAGE.NO_DATA,
    };
  };

  return (
    <div className={s.dataTable}>
      <MrGroupedDataTable mainTableProps={mainTableProps} buildSubTableProps={buildSubTableProps} />
    </div>
  );
};

DashboardDataTable.propTypes = {
  metricsData: pt.array.isRequired,
  clusters: pt.array.isRequired,
  pipelines: pt.array.isRequired,
  setMetrics: pt.func.isRequired,
  setError: pt.func.isRequired,
  loadingMetrics: pt.func,
  isLoading: pt.bool.isRequired,
  setEventMetrics: pt.func.isRequired,
  setEventMetricsError: pt.func.isRequired,
  eventMetricsError: pt.object.isRequired,
  since: pt.instanceOf(Date).isRequired,
  until: pt.instanceOf(Date).isRequired,
  expandedRows: pt.array,
  setExpandedRows: pt.func,
  integrations: pt.array,
  noDataComponentMessage: pt.string,
  integrationIds: pt.array,
  groupBy: pt.string,
  configuredClusters: pt.array,
  filterCount: pt.number.isRequired,
  defaultFilter: pt.bool.isRequired,
};

export default DashboardDataTable;
