import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';

import isAllowedToDeleteSchemas from '@ion/api/schemas/permissions/isAllowedToDeleteSchemas';

export default function DeleteEventButton({ row }) {
  const hasPermission = isAllowedToDeleteSchemas();
  const handleOnClick = () => {
    console.log('DeleteEventButton clicked', row);
  };

  return (
    <IconButton disabled={!hasPermission} data-test-id="deleteEventButton" onClick={handleOnClick}>
      <Trash02Icon />
    </IconButton>
  );
}

DeleteEventButton.propTypes = {
  row: PropTypes.object.isRequired,
};
