import React from 'react';
import { Button, Stack } from '@metarouter-private/mr-mui';
import { CANCEL_BUTTON_PROPS, DEFAULT_STACK_PROPS, SUBMIT_BUTTON_PROPS } from './default-button-props';
import PropTypes from 'prop-types';

export default function PlaybookFooterButtons({ onCancel, submitLabel, disabled, ...rest }) {
  return (
    <Stack {...DEFAULT_STACK_PROPS}>
      <Button {...CANCEL_BUTTON_PROPS} onClick={onCancel}>
        Cancel
      </Button>
      <Button {...SUBMIT_BUTTON_PROPS} disabled={disabled} {...rest}>
        {submitLabel}
      </Button>
    </Stack>
  );
}

PlaybookFooterButtons.propTypes = {
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
