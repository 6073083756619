import React from 'react';
import PropTypes from 'prop-types';

import { Stack } from '@metarouter-private/mr-mui';

import DeleteEventButton from './DeleteEventButton';
import EditEventButton from './EditEventButton';

const STACK_PROPS = {
  alignItems: 'center',
  direction: 'row',
  spacing: 2,
};

export default function ActionsCell({ row }) {
  const onClick = () => {
    console.log('EditEventButton clicked');
  };

  return (
    <Stack {...STACK_PROPS}>
      <EditEventButton onClick={onClick} />
      <DeleteEventButton row={row} />
    </Stack>
  );
}

ActionsCell.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
