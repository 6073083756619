import React, { useState, useCallback } from 'react';
import { debounce as _debounce } from 'lodash';
import { useTransformPlaybookContext } from '../../../context/transform-playbook-context';
import { isValidJSONObject } from '@ion/mrdash';
import { ERRORS } from '../errors';
import { UncontrolledMrCodeEditor } from '@ion/components';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

export default function InputEditor() {
  const { errorMessage, clearErrorMessage, validateAndSetInput, input } = useInputEditorState();

  const [trackEditInput] = useTrackWithUser('edit_input_event');

  function onSetInput(event) {
    const code = event.target.value;
    validateAndSetInput(code);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSetInput = useCallback(
    _debounce(event => {
      onSetInput(event);
      trackEditInput();
    }, 1200),
    []
  );

  const onChange = event => {
    if (errorMessage) {
      clearErrorMessage();
    }
    debouncedOnSetInput(event);
  };

  return (
    <UncontrolledMrCodeEditor
      name="inputEvent"
      lang="json"
      errorMsg={errorMessage}
      onBlur={onSetInput}
      onChange={onChange}
      defaultValue={input}
      rows={30}
    />
  );
}

function useInputEditorState() {
  const { input, setInput, setHasValidInput } = useTransformPlaybookContext();
  const [errorMessage, setErrorMessage] = useState(null);

  const clearErrorMessage = useCallback(() => setErrorMessage(null), [setErrorMessage]);

  function validateAndSetInput(code) {
    setInput(code);
    if (!code) {
      setHasValidInput(false);
      setErrorMessage(null);
    } else if (isValidJSONObject(code)) {
      setHasValidInput(true);
      setErrorMessage(null);
    } else {
      setHasValidInput(false);
      setErrorMessage(ERRORS.INVALID_JSON);
    }
  }

  return { input, errorMessage, clearErrorMessage, validateAndSetInput };
}
