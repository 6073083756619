import YAML from 'yaml';
import { isNull as _isNull, isObject as _isObject } from 'lodash';

export default function isValidYaml(yamlString) {
  try {
    const yml = YAML.parse(yamlString);

    if (_isNull(yml)) {
      return true;
    }
    return _isObject(yml);
  } catch (e) {
    return false;
  }
}
