import React from 'react';
import pt from 'prop-types';
import { AUTH0_USER_ROLES } from '@ion/api/auth0/roles/constants';
import { EyeIcon, IntegrationIcon, PipelineIcon, Tool01Icon } from '@metarouter-private/mr-mui';

export default function RoleIcon({ roleName }) {
  switch (roleName) {
    case AUTH0_USER_ROLES.DEPLOYMENT_MANAGER:
      return <PipelineIcon />;
    case AUTH0_USER_ROLES.INTEGRATION_MANAGER:
      return <IntegrationIcon />;
    case AUTH0_USER_ROLES.ADMIN:
      return <Tool01Icon />;
    case AUTH0_USER_ROLES.READ_ONLY:
      return <EyeIcon />;
    default:
      return <></>;
  }
}

RoleIcon.propTypes = {
  roleName: pt.string.isRequired,
};
