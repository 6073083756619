import React from 'react';

import pt from 'prop-types';
import MrRightPanel from 'app/event-monitoring/event-schemas/panel/right-panel';

import s from 'app/event-monitoring/event-schemas/panel/index.module.scss';

import ReportDetails from 'app/event-monitoring/event-schemas/panel/report-details';
import SchemaPanelState from 'app/event-monitoring/event-schemas/panel/state';
import GetEventSchemaDetails from 'app/event-monitoring/event-schemas/panel/get-schema-details';
import SchemaTree from 'app/event-monitoring/event-schemas/panel/tree';
import PipelineSelect from 'app/event-monitoring/event-schemas/panel/pipeline-select';
import Export from 'app/event-monitoring/event-schemas/panel/export';
import handleArrayOf from './handle-array-of';

const ReportPanel = ({ cluster, earliestSeen, timeframeHours, report, clearReport }) => {
  const state = SchemaPanelState(report);
  const properties = handleArrayOf(state?.details)?.properties;

  return (
    <MrRightPanel closeModal={clearReport}>
      <GetEventSchemaDetails
        cluster={cluster}
        earliestSeen={earliestSeen}
        eventName={report.eventName}
        setSchemaDetails={state.setDetails}
        setLoadingDetails={state.loadingDetails}
        filteredPipelines={state.filteredPipelines}
      />
      <div className={s.container}>
        <div className={s.top}>
          <div className={s.eventTitle}>{report.eventName} Event Schema</div>
          <div className={s.corner}>
            <div className={s.select}>
              <PipelineSelect
                pipelines={report.pipelines}
                setFilterPipelines={state.setFilterPipelines}
                filteredPipelines={state.filteredPipelines}
                setShow={state.setShow}
                loadingDetails={state.isLoadingDetails}
              />
            </div>
            {state.details?.properties && <Export eventName={report.eventName} properties={state.details.properties} />}
          </div>
        </div>
        {state.show && <ReportDetails details={state} timeframeHours={timeframeHours} />}
        {state.show && properties && <SchemaTree properties={properties} />}
      </div>
    </MrRightPanel>
  );
};

ReportPanel.propTypes = {
  cluster: pt.shape({
    id: pt.string,
  }).isRequired,
  earliestSeen: pt.string.isRequired,
  timeframeHours: pt.number.isRequired,
  report: pt.shape({
    combinations: pt.string.isRequired,
    eventName: pt.string.isRequired,
    lastSeen: pt.instanceOf(Date).isRequired,
    totalCount: pt.string.isRequired,
    pipelines: pt.array.isRequired,
  }).isRequired,
  clearReport: pt.func.isRequired,
};

export default ReportPanel;
